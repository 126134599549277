import { useContractDetail } from "@/hooks/useContractDetail";
import { useRelyingPartyDisplaySettings } from "@/hooks/useRelyingPartyDisplaySettings";
import { path } from "@/routes";
import type { AxiosError } from "axios";
import { AtlasTracking } from "nid-common";
import { postUndoCancelContract } from "nid-common/api/account/postUndoCancelContract";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { UndoCancelContractErrorResponse } from "../../../../../../../packages/nid-common/api/account/postUndoCancelContract";

export const useServiceContractFeature = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const settings = useRelyingPartyDisplaySettings(clientId || "");
  const contract = useContractDetail(clientId || "");
  const [undoCancelContractCompleted, setUndoCancelContractCompleted] =
    useState(false);

  useEffect(() => {
    if (contract.status === "nkdk_inactive") {
      navigate(path.services.contract.maintenance(clientId || ""), {
        replace: true,
      });
      return;
    }
    if (
      settings.status === "not_found" ||
      contract.status === "contract_not_found"
    ) {
      navigate(path.services.root, { replace: true });
      return;
    }
    if (contract.status === "error" || settings.status === "error") {
      navigate(path.error.root, { replace: true });
      return;
    }
  }, [settings, contract]);

  if (
    contract.status === "loading" ||
    settings.status === "loading" ||
    settings.status === "error" ||
    settings.status === "not_found" ||
    contract.status === "error" ||
    contract.status === "contract_not_found" ||
    contract.status === "nkdk_inactive" ||
    clientId === undefined
  ) {
    return {
      status: "loading",
    } as const;
  }

  const onUndoCancelStart = async () => {
    try {
      await postUndoCancelContract(clientId);
    } catch (e) {
      const response = (e as AxiosError<UndoCancelContractErrorResponse>)
        .response;
      if (
        response?.data.error === "service_not_found" ||
        response?.data.error === "plan_not_found" ||
        response?.data.error === "data_out_of_scope"
      ) {
        navigate(path.services.root);
      } else if (response?.data.error === "nkdk_inactive") {
        navigate(path.services.contract.maintenance(clientId || ""), {
          replace: true,
        });
      } else {
        AtlasTracking.unknownError(e);
        navigate(path.error.root);
      }
    }
  };

  const onUndoCancelFinished = async () => {
    await contract.mutate();
    setUndoCancelContractCompleted(true);
  };

  return {
    status: "ok",
    name: settings.relyingParty.name,
    logo: settings.relyingParty.logo,
    clientId: settings.relyingParty.clientId,
    contractDetail: contract.contractDetail,
    onUndoCancelStart,
    onUndoCancelFinished,
    undoCancelContractCompleted,
  } as const;
};
