import { Box, LinkButton } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { Cautions } from "@/components/ui/Cautions/Cautions";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { ContractCancelSurvey } from "./ContractCancelSurvey";
import { ContractContent } from "./ContractContent";
import { useContractCancelFeature } from "./hooks/useContractCancelFeature";

export const ServiceContractCancelFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const { contract, survey, status, submit, registers } =
    useContractCancelFeature();

  if (status !== "ok") return <ContentSkeletonLoader />;

  return (
    <form noValidate>
      <Box className="nid-column-item-initial nid-item-gap24">
        <ContractContent
          contract={{
            service: contract.serviceName,
            plan: contract.planName,
            isFreeTrial: contract.isFreeTrial,
            isPaidPlan: contract.isPaid,
          }}
        />
        {survey && (
          <ContractCancelSurvey
            choices={survey.choices}
            registers={registers}
            text={survey.text}
          />
        )}
        {contract.isPaid && (
          <Cautions
            hasMarginBottom={false}
            cautionTitle={t("services.service_contract_cancel.note.label")}
            cautionList={[
              ...(contract.isFreeTrial
                ? [
                    {
                      text: t(
                        "services.service_contract_cancel.note.trial_cancel_notice",
                      ),
                    },
                    {
                      text: t(
                        "services.service_contract_cancel.note.separate_contract",
                        { serviceName: contract.serviceName },
                      ),
                    },
                    {
                      text: t(
                        "services.service_contract_cancel.note.trial_usage_until_end",
                      ),
                    },
                  ]
                : [
                    {
                      text: t(
                        "services.service_contract_cancel.note.separate_contract",
                        { serviceName: contract.serviceName },
                      ),
                    },
                    {
                      text: t(
                        "services.service_contract_cancel.note.usage_until_end",
                      ),
                    },
                  ]),
            ]}
          />
        )}
        <LinkButton
          size="full"
          data-testid="submit"
          onClick={submit}
          to={path.services.contract.cancel.confirm(contract.clientId)}
        >
          {t("services.service_contract_cancel.confirm")}
        </LinkButton>
      </Box>
      <Box className="nid-item-center mt-4">
        <BackButton
          to={path.services.contract.root(contract.clientId)}
          buttonText={t("services.service_contract.back")}
        />
      </Box>
    </form>
  );
};
