import type * as React from "react";

import {
  Box,
  FormControl,
  InputField,
  Typography2,
  type UseFormRegisterReturn,
} from "nikkei-ui";

import { Row } from "@/components/ui/Row";
import RequiredTag from "../../Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.NameField";

type NameFieldProps = {
  label: string;
  placeholder?: {
    first?: string;
    last?: string;
  };
  errors?: {
    last?: { message?: string };
    first?: { message?: string };
  };
  errorDetails?: React.ReactNode;
  testId?: string;
  registers?: {
    first?: UseFormRegisterReturn<string>;
    last?: UseFormRegisterReturn<string>;
  };
  helperText?: string;
};

const NameField: React.FC<NameFieldProps> = ({
  registers,
  errors,
  errorDetails,
  testId = "name",
  ...props
}) => {
  const status = {
    first: errors?.first ? "error" : undefined,
    last: errors?.last ? "error" : undefined,
  } as const;
  const errorMessage = errors?.first?.message || errors?.last?.message;

  return (
    <fieldset className="nid-account-edit-fieldset">
      <Typography2
        as="legend"
        bold
        className="mb-1"
        color={errorMessage ? "red90" : undefined}
      >
        {props.label}
        <RequiredTag on={true} />
      </Typography2>
      <Row className="nid-space-between mb-1">
        <Box className="nid-input-control-head">
          <InputField
            status={status.last}
            placeholder={props.placeholder?.last}
            aria-label={props.placeholder?.last}
            id={`input-${testId}-last`}
            data-testid={`input-${testId}-last`}
            maxLength={60}
            {...registers?.last}
          />
        </Box>
        <Box className="nid-input-control-head">
          <InputField
            status={status.first}
            placeholder={props.placeholder?.first}
            aria-label={props.placeholder?.first}
            id={`input-${testId}-first`}
            data-testid={`input-${testId}-first`}
            maxLength={60}
            {...registers?.first}
          />
        </Box>
      </Row>
      {props.helperText && <Box>{props.helperText}</Box>}
      <FormControl.Validation
        className="nid-error-field"
        status={errorMessage ? "error" : undefined}
        data-testid={`error-${testId}`}
      >
        {errorMessage}
      </FormControl.Validation>
      {errorDetails}
    </fieldset>
  );
};

NameField.displayName = DISPLAY_NAME;
const Root = NameField;
export { Root, NameField };
export type { NameFieldProps };
export default NameField;
