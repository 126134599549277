import {
  Box,
  ButtonBase as Button,
  FormControl,
  Select,
  Typography2,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { DomesticAddressField } from "@/components/ui/Form/AddressField";
import OverseasAddressField from "@/components/ui/Form/AddressField/OverseasAddressField";
import CharacterError from "@/components/ui/Form/CharacterError";
import { Row } from "@/components/ui/Row";
import RequiredTag from "@/components/ui/Tag/RequiredTag";
import { usePersonalAddressInputFeature } from "@/features/Personal/Address/hooks/usePersonalAddressInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalAddressInputFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    registers,
    status,
    addresses,
    refreshAddress,
    countries,
    errors,
    required,
    buttonEnabled,
    isDomestic,
  } = usePersonalAddressInputFeature();

  const domesticAddressCharacterError =
    errors?.domestic?.address1?.type === "invalid_character" ||
    errors?.domestic?.address2?.type === "invalid_character";
  if (status !== "ok") return <ContentSkeletonLoader />;

  return (
    <Box as="section" className="nid-section nid-section-without-background">
      <Box className="nid-section-inner">
        <form onSubmit={handleSubmit} noValidate>
          <Box className="mb-2">
            <fieldset className="nid-account-edit-fieldset">
              <Typography2
                as="legend"
                size="16"
                bold
                className="mb-2"
                color={errors.countryCode ? "red90" : undefined}
              >
                <label htmlFor="select-country">
                  {t("personal.edit.address.label.country")}
                </label>
                <RequiredTag on={true} />
              </Typography2>
              <Row className="nid-space-between mb-1">
                <Select
                  containerclassname="w-full"
                  options={countries}
                  id="select-country"
                  data-testid="select-country"
                  defaultValue="0"
                  status={errors.countryCode ? "error" : undefined}
                  {...registers.country}
                />
              </Row>
              <FormControl.Validation
                className="nid-error-field"
                status={errors.countryCode ? "error" : undefined}
              >
                {errors.countryCode ? errors.countryCode.message : undefined}
              </FormControl.Validation>
            </fieldset>

            {isDomestic ? (
              <DomesticAddressField
                address={addresses}
                refreshAddress={refreshAddress}
                errors={errors.domestic}
                errorDetails={
                  domesticAddressCharacterError ? <CharacterError /> : undefined
                }
                zipCodeRequired={required.domestic?.zipCode}
                registers={registers.domestic}
              />
            ) : (
              <OverseasAddressField
                errors={errors.overseas}
                registers={registers.overseas}
              />
            )}
          </Box>

          <Box className="mb-2">
            <FormControl.Validation
              className="nid-error-field"
              status={errors.api ? "error" : undefined}
            >
              {errors.api?.message}
            </FormControl.Validation>
          </Box>

          <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
            {t("personal.edit.button.confirm")}
          </Button>
        </form>

        <Box className="nid-item-center">
          <BackButton
            to={path.personal.root}
            buttonText={t("personal.edit.button.cancel")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalAddressInputFeature;
