import type * as React from "react";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaAuthenticatorConfirmFeature } from "@/features/Security/Mfa/Authenticator/SecurityMfaAuthenticatorConfirmFeature";
import { Typography2 } from "nikkei-ui";

const SecurityMfaAuthenticatorConfirmPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        title={t("security.mfa.authenticator.confirm.title")}
        testId="article-security-mfa-authenticator-confirm"
      >
        <Typography2 as="p" className="nid-article-description">
          {t("security.mfa.authenticator.confirm.description")}
        </Typography2>
      </ArticleTitle>
      <SecurityMfaAuthenticatorConfirmFeature />
    </>
  );
};

export default SecurityMfaAuthenticatorConfirmPage;
