import type React from "react";

import { path } from "@/routes";
import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { NavigationItem } from "../Navigation/NavigationItem";

export const PaymentsNavigation: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow-nopadding mb-6"
    >
      <Box className="nid-section-inner nid-body-nav-container p-0">
        <NavigationItem
          label={t("payments.navigation.histories")}
          to={path.payments.histories.root}
          noBorder
          testId="link-payments-histories"
          linkSpace
        />
      </Box>
    </Box>
  );
};
