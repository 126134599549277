import type React from "react";

import { Link2 as Link, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import ServicesListFeature from "@/features/Services/List/ServicesListFeature";

import { HelperTextWithModal } from "@/components/ui/ServiceList/HelperTextWithModal";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

export const ServicesTopPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.services")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography2 as="span" size="14">
            {t("breadcrumbs.services")}
          </Typography2>
        </>
      }
      description={t("services.description")}
    >
      <LoginWallProvider>
        <ArticleTitle
          title={t("services.list.title")}
          testId="article-services-list"
        >
          <Typography2 as="p" className="nid-article-description">
            {t("services.list.description")}
          </Typography2>
          <HelperTextWithModal />
        </ArticleTitle>
        <ServicesListFeature />
      </LoginWallProvider>
    </Layout>
  );
};

export default ServicesTopPage;
