import type { PaymentsCard, PaymentsService } from "@/hooks/usePaymentCards";

import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import { CreditCard } from "@/components/ui/Payments/Top/PaymentsItem";
import { path } from "@/routes";
import { Box, LinkButton, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./PaymentsFeature.module.css";

const Services: React.FC<{ services: PaymentsService[]; valid: boolean }> = ({
  services,
  valid,
}) => {
  const { t } = useTranslation();
  return (
    <Box className={`${styles.services} ${!valid && styles.unpaidServices}`}>
      <Typography2
        size="12"
        bold
        data-testid="service-description"
        color={valid ? undefined : "red90"}
      >
        {t("payments.top.text.service")}
      </Typography2>
      <Box className="my-2">
        {services.map((service) => (
          <Box
            className={styles.serviceItem}
            key={service.serviceId}
            data-testid="service-name"
          >
            {service.name} {service.planId ? `（${service.planName}）` : ""}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CreditCardPayments: React.FC<{ card: PaymentsCard }> = ({ card }) => {
  const { t } = useTranslation();
  const cardStyle =
    card.status !== "valid" && card.services.length > 0
      ? "nid-typography--color-error"
      : "";

  return (
    <>
      <Box className="nid-section-header">
        <CreditCard brand={card.brand}>
          <Typography2 as="h2" data-testid="credit-card-number">
            <Box className={`nid-section-title ${cardStyle}`}>
              •••• {card.last4Number}
            </Box>
          </Typography2>
        </CreditCard>
        {card.services.length > 0 ? (
          <LinkButton
            to={path.payments.update.card(card.id)}
            variant="border"
            data-testid="button-update"
            className={styles.button}
          >
            {t("payments.top.button.update")}
          </LinkButton>
        ) : (
          <LinkButton
            to={`${path.payments.delete.root(card.id)}`}
            variant="border"
            data-testid="button-delete"
            className={styles.button}
          >
            {t("payments.top.button.delete")}
          </LinkButton>
        )}
      </Box>
      <Typography2 data-testid="expire">
        {`${t("payments.top.text.expire")} ${card.expire.formatString}`}
      </Typography2>
      {card.services.length > 0 ? (
        <Services services={card.services} valid={card.status !== "unpaid"} />
      ) : null}
    </>
  );
};

const PaymentsCardTab: React.FC<{ card: PaymentsCard }> = ({ card }) => {
  const { t } = useTranslation();
  return (
    <Box
      data-testid={`payments-card-${card.id}`}
      as="section"
      className="nid-section nid-section-overflow"
    >
      {card.status === "invalid" && card.services.length > 0 && (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="alert"
            title={t("payments.top.title.invalidCard")}
            withBorder={false}
          />
        </Box>
      )}
      {card.status === "unpaid" && (
        <Box className="nid-section-inner mb-4">
          <AlertMessage
            testId="alert"
            title={t("payments.top.title.unpaidServices")}
            withBorder={false}
          />
        </Box>
      )}
      <Box className="nid-section-inner">
        <CreditCardPayments card={card} />
      </Box>
    </Box>
  );
};

export const PaymentsCardsFeature: React.FC<{ cards: PaymentsCard[] }> = ({
  cards,
}) => {
  const { t } = useTranslation();

  const unpaidCards = cards.filter(
    (card) => card.status === "unpaid" && card.services.length > 0,
  );

  const invalidCards = cards.filter(
    (card) => card.status === "invalid" && card.services.length > 0,
  );

  const validUsedCards = cards.filter(
    (card) => card.status === "valid" && card.services.length > 0,
  );

  const unusedCards = cards.filter((card) => card.services.length === 0);

  return (
    <>
      {unpaidCards.map((card) => (
        <PaymentsCardTab key={card.id} card={card} />
      ))}
      {invalidCards.map((card) => (
        <PaymentsCardTab key={card.id} card={card} />
      ))}
      {validUsedCards.map((card) => (
        <PaymentsCardTab key={card.id} card={card} />
      ))}

      {unusedCards.length > 0 ? (
        <>
          <Box className="nid-section-transparent">
            <Box className="nid-section-inner">
              {t("payments.top.text.unused")}
            </Box>
          </Box>
          {unusedCards.map((card) => (
            <PaymentsCardTab key={card.id} card={card} />
          ))}
        </>
      ) : null}
    </>
  );
};
