import type * as React from "react";

import { Box, ChevronRightIcon, Typography2 } from "nikkei-ui";

const DISPLAY_NAME = "Nid.BreadcrumbItem";

type BreadcrumbItemProps = {
  separator?: string;
  className?: string;
  isLast?: boolean;
  children: React.ReactNode;
};

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  separator,
  className = "",
  isLast,
  children,
}) => {
  const breadcrumbSeparator = (separator: string | undefined) => {
    if (typeof separator === "string") {
      return (
        <Typography2 as="span" className="nid-breadcrumbs-separator">
          {separator}
        </Typography2>
      );
    }
    return <ChevronRightIcon />;
  };
  const nidClass = `nid-breadcrumbs-item ${className}`;
  return (
    <Box as="li" className={nidClass}>
      {children}
      {!isLast && breadcrumbSeparator(separator)}
    </Box>
  );
};

BreadcrumbItem.displayName = DISPLAY_NAME;
const Root = BreadcrumbItem;
export { BreadcrumbItem, Root };
export type { BreadcrumbItemProps };
export default BreadcrumbItem;
