import { Link2 as Link, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "@/components/ui/Layout";

export const PaymentsDeletePageWrapper = (): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isComplete = pathname.includes(path.payments.delete.complete);

  const breadcrumbText = isComplete
    ? "breadcrumbs.payments_card_delete_complete"
    : "breadcrumbs.payments_card_delete";

  return (
    <Layout
      title={t(breadcrumbText)}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} size="14">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography2 as="span" size="14">
            {t(breadcrumbText)}
          </Typography2>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider maxAge={1800}>
        <Outlet />
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsDeletePageWrapper;
