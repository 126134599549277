import { Box, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Service.module.css";

const Separator = () => {
  return <hr className="nid-separator w-full" />;
};

type ContractItem = {
  title?: string;
  text: React.ReactNode;
  hasTopLine?: boolean;
  testId: string;
};

export const ServiceContractItem = ({
  title,
  text,
  hasTopLine = false,
  testId,
}: ContractItem) => {
  return (
    <>
      {hasTopLine && <Separator />}
      <Box className="pt-3 pb-3">
        <Box
          className="nid-column-item-start nid-item-gap4"
          data-testid={testId}
        >
          {title && (
            <Typography2 size="14" bold testId={`${testId}-title`}>
              {title}
            </Typography2>
          )}
          <Typography2 testId={`${testId}-text`}>{text}</Typography2>
        </Box>
      </Box>
    </>
  );
};

type ContractAmount = {
  amount: number;
  children?: React.ReactNode;
  discountAmount?: number;
};

type ServiceContractItemWithAmountProps = ContractItem & ContractAmount;

/**
 * 金額を表示できるコンポーネント
 * ディスカウントがtrueの時は,
 * discount, discountColor, discountAmount, discountAmountColorを追加
 */
export const ServiceContractItemWithAmount = ({
  title,
  text,
  amount,
  testId = "",
  children,
  hasTopLine = false,
  discountAmount,
}: ServiceContractItemWithAmountProps) => {
  const { t } = useTranslation();
  return (
    <>
      {hasTopLine && <Separator />}
      <Box className="pt-3 pb-3" data-testid={testId}>
        <Box className="nid-column-item-start nid-item-gap4">
          <Typography2 as="p" size="14" bold testId={`${testId}-title`}>
            {title}
          </Typography2>
          {children}
          <PlanWithAmountRowItem
            label={text}
            amount={amount}
            testId={testId}
            isPrimary={discountAmount === undefined}
          />
          {discountAmount !== undefined && (
            <PlanWithAmountRowItem
              label={t(
                "services.service_contract.content.price_type.set_discounts",
              )}
              amount={discountAmount}
              testId={`${testId}-discount`}
              isPrimary={true}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const PlanWithAmountRowItem = ({
  label,
  amount,
  testId,
  isPrimary,
}: {
  label: React.ReactNode;
  amount: number;
  testId: string;
  isPrimary: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={`${styles.headerAlignItemsEnd} nid-item-gap4 w-full`}
      data-testid={`${testId}-amount`}
    >
      <Box className="nid-column-item-start nid-item-gap4">
        <Typography2
          as="p"
          color={isPrimary ? undefined : "gray90"}
          data-testid={`${testId}-label`}
        >
          {label}
        </Typography2>
      </Box>
      <Box className="nid-column-item-end nid-item-gap4">
        <Typography2
          as="p"
          color={isPrimary ? undefined : "gray90"}
          className={isPrimary ? undefined : styles.amountLine}
          data-testid={`${testId}-value`}
        >
          {t("services.service_contract.content.price", {
            price: amount.toLocaleString("ja-JP"),
          })}
        </Typography2>
      </Box>
    </Box>
  );
};
