import { Box, Link2 as Link, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { AccountCircleIcon } from "@/components/icon/AccountCircleIcon";
import { CardIcon } from "@/components/icon/CardIcon";
import { EmailIcon } from "@/components/icon/EmailIcon";
import { KeyIcon } from "@/components/icon/KeyIcon";
import { SecurityIcon } from "@/components/icon/SecurityIcon";
import { ServicesIcon } from "@/components/icon/ServicesIcon";
import { path } from "@/routes";
import styles from "./AccountPanel.module.css";

type LastPasswordChanged = {
  year: number;
  month: number;
  day: number;
};

type AccountPanelProps = {
  isAuthenticated?: boolean;
  isRelyingPartyRegistered?: boolean;
  lastPasswordChanged?: LastPasswordChanged;
};

type MenuItem = {
  title: string;
  description: string;
  link: string;
  icon: React.FC;
  testId?: string;
};

export const AccountPanel: React.FC<AccountPanelProps> = ({
  isAuthenticated = false,
  isRelyingPartyRegistered = false,
  lastPasswordChanged,
}) => {
  const { t } = useTranslation();

  const menuList: MenuItem[] = [
    {
      title: t("account_top.menu.personal.title"),
      description: t("account_top.menu.personal.description"),
      link: path.personal.root,
      icon: AccountCircleIcon,
      testId: "account-top-menu-personal",
    },
    {
      title: t("account_top.menu.email.title"),
      description: t("account_top.menu.email.description"),
      link: path.personal.emailChange.root,
      icon: EmailIcon,
      testId: "account-top-menu-email",
    },
    {
      title: t("account_top.menu.services.title"),
      description: isAuthenticated
        ? isRelyingPartyRegistered
          ? t("account_top.menu.services.description_relying_party_registered")
          : t(
              "account_top.menu.services.description_relying_party_unregistered",
            )
        : t("account_top.menu.services.description_unauthenticated"),
      link: path.services.root,
      icon: ServicesIcon,
      testId: "account-top-menu-services",
    },
    {
      title: t("account_top.menu.payments.title"),
      description: t("account_top.menu.payments.description"),
      link: path.payments.root,
      icon: CardIcon,
      testId: "account-top-menu-payments",
    },
    {
      title: t("account_top.menu.password.title"),
      description:
        isAuthenticated && lastPasswordChanged
          ? t("account_top.menu.password.description", {
              year: lastPasswordChanged.year,
              month: lastPasswordChanged.month,
              day: lastPasswordChanged.day,
            })
          : t("account_top.menu.password.description_unauthenticated"),
      link: path.security.passwordChange.root,
      icon: KeyIcon,
      testId: "account-top-menu-password",
    },
    {
      title: t("account_top.menu.security.title"),
      description: t("account_top.menu.security.description"),
      link: path.security.root,
      icon: SecurityIcon,
      testId: "account-top-menu-security",
    },
  ];

  return (
    <Box as="section" className={styles.section}>
      <Typography2 size="18" bold data-testid="personal_info">
        {t("account_top.title.personal_info")}
      </Typography2>

      <Box as="article" className={styles.panel}>
        {menuList.map((item) => {
          const Icon = item.icon;
          return (
            <Link
              to={item.link}
              linkClassName={styles.panelLink}
              key={item.link}
              data-testid={item.testId}
            >
              <Box className={styles.panelCard}>
                <Box className={styles.panelCardTitle}>
                  <Box className={styles.panelCardIcon}>
                    <Icon />
                  </Box>
                  <Typography2 as="p" size="18" bold>
                    {item.title}
                  </Typography2>
                </Box>
                <Box className={styles.panelCardDescription}>
                  <Typography2 as="p" size="13">
                    {item.description}
                  </Typography2>
                </Box>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};
