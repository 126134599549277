import { useContractInfoProvider } from "@/features/Services/Contract/Cancel/ContractInfoProvider";
import { path } from "@/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContractCancel } from "./useContractCancel";

export const useContractCancelCompleteFeature = () => {
  const navigate = useNavigate();
  const { contractInfo } = useContractInfoProvider();
  const { status, clientId, contract } = useContractCancel();

  useEffect(() => {
    if (contractInfo === undefined) {
      navigate(path.services.contract.root(clientId), {
        replace: true,
      });
      return;
    }
    if (
      status === "ok" &&
      contractInfo.isPaidPlan &&
      contract.contractDetail.terminationInfo === undefined
    ) {
      navigate(path.services.contract.root(clientId), {
        replace: true,
      });
      return;
    }
  }, [contract, clientId, contractInfo]);

  if (contractInfo === undefined || status === "loading") {
    return { status: "loading" } as const;
  }

  if (contractInfo.isPaidPlan) {
    if (
      contract?.contractDetail === undefined ||
      contract.contractDetail.terminationInfo === undefined ||
      status === "contract_not_found"
    ) {
      return { status: "loading" } as const;
    }

    return {
      status: "ok",
      clientId: clientId,
      serviceName: contract.contractDetail.serviceName,
      planName: contract.contractDetail.planName,
      expiration: {
        year: contract.contractDetail.terminationInfo.applyDate.year,
        month: contract.contractDetail.terminationInfo.applyDate.month,
        date: contract.contractDetail.terminationInfo.applyDate.date,
      },
      isPaidPlan: true,
    } as const;
  }

  return {
    status: "ok",
    clientId: contractInfo.clientId,
    serviceName: contractInfo.serviceName,
    planName: contractInfo.planName,
    isPaidPlan: false,
  } as const;
};
