import { AtlasTracking, useUserStatus } from "nid-common";
import {
  getAdsSegments,
  getCampaignCancelPagePopupIdrp,
} from "nid-common/api/account";
import useSWR from "swr";

const popUpFileName = import.meta.env.VITE_CAMPAIGN_CANCEL_POPUP;

export const useContractRetentionPopup = (rpid: string) => {
  const { data } = useUserStatus();
  const serialId = data?.serial_id;

  const {
    data: segmentData,
    error: segmentError,
    isLoading: segmentLoading,
  } = useSWR(
    serialId ? `/ads/v1/segments/${serialId}` : null,
    serialId ? () => getAdsSegments(serialId as string) : null,
  );

  const {
    data: cancelBeforePopupData,
    error: cancelBeforePopupError,
    isLoading: cancelBeforePopupLoading,
  } = useSWR(
    rpid
      ? `/promotion/campaign/cancel_page_popup_idrp/${rpid.toLowerCase()}`
      : null,
    rpid ? () => getCampaignCancelPagePopupIdrp(rpid, popUpFileName) : null,
  );

  if (segmentLoading || cancelBeforePopupLoading) {
    return { status: "loading" } as const;
  }

  if (segmentError || cancelBeforePopupError || !cancelBeforePopupData) {
    return { status: "error" } as const;
  }

  const hasSegments = (segmentData?.data?.segment_ids?.length ?? 0) > 0;
  const hasSegmentInfo =
    (cancelBeforePopupData?.data?.segment_info?.length ?? 0) > 0;

  if (!hasSegments || !hasSegmentInfo) {
    return { status: "not_found" } as const;
  }

  const matchedSegment = cancelBeforePopupData.data.segment_info.filter(
    (item) => {
      const segmentIds = segmentData?.data?.segment_ids;
      // NOTE: Stringで確認
      return segmentIds
        ? segmentIds.some((id) => String(id) === String(item.segment_id))
        : false;
    },
  );

  if (matchedSegment.length === 0) {
    return { status: "not_found" } as const;
  }

  if (matchedSegment.length > 1) {
    return { status: "too_many_segments" } as const;
  }

  const onStartCancel = async () => {
    AtlasTracking.track.clickButton("cancel_nkdk", {
      campaign: `${rpid}_cancellation`,
      content: "monthly",
      segment: matchedSegment[0].segment_id,
    });
  };

  return {
    status: "ok",
    segmentInfo: matchedSegment[0],
    onStartCancel,
  } as const;
};
