import type React from "react";

import { Link2 as Link, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { Layout } from "@/components/ui/Layout";
import { ServiceFeature } from "@/features/Services/ServiceFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { useServicePage } from "./hooks/useServicePage";

export const ServicePage: React.FC = () => {
  const { t } = useTranslation();
  const { serviceName } = useServicePage();

  return (
    <Layout
      title={serviceName}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.services.root} size="14">
            {t("breadcrumbs.services")}
          </Link>
          <Typography2 as="span" size="14" data-testid="breadcrumb-last-item">
            {serviceName}
          </Typography2>
        </>
      }
      description={t("services.description")}
    >
      <LoginWallProvider>
        <ServiceFeature />
      </LoginWallProvider>
    </Layout>
  );
};

export default ServicePage;
