import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import PersonalEmailChangeInputFeature from "@/features/Personal/EmailChange/PersonalEmailChangeInputFeature";
import { Typography2 } from "nikkei-ui";

export const PersonalEmailChangePage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-email-change-input"
        title={t("personal.edit.email_change.title")}
      >
        <Typography2 as="p" className="nid-article-description">
          {t("personal.edit.email_change.description")}
        </Typography2>
      </ArticleTitle>
      <PersonalEmailChangeInputFeature />
    </>
  );
};

export default PersonalEmailChangePage;
