import { getClientIdFromServiceId } from "@/lib/getClientIdFromServiceId";
import { path } from "@/routes";
import { type Card, getPaymentCards } from "nid-common/api/account";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

export type PaymentsService = {
  clientId: string;
  serviceId: string;
  name: string;
  planId?: string;
  planName?: string;
  unpaid: boolean;
};

export type CreditCardBrand =
  | "AmericanExpress"
  | "DinersClub"
  | "JCB"
  | "Mastercard"
  | "Visa"
  | "Unknown";

export type PaymentsCardStatus = "valid" | "unpaid" | "invalid";

export type PaymentsCard = {
  id: string;
  maskedNumber: string;
  last4Number: string;
  expire: {
    month: string;
    year: string;
    formatString: string;
  };
  brand: CreditCardBrand;
  status: PaymentsCardStatus;
  services: PaymentsService[];
};

export type usePaymentsType = {
  mutate: () => void;
  cards: PaymentsCard[];
  status: "ok" | "loading";
};

export const mapToPaymentsCard = (card: Card): PaymentsCard => {
  const expireYear = card.card_exp.slice(0, 2);
  const expireMonth = card.card_exp.slice(2, 4);

  const unpaidServices = new Set(
    card.unpaid_services.map((service) => service.service_id),
  );

  return {
    id: card.card_id,
    maskedNumber: card.card_no,
    last4Number: card.card_no_last4,
    expire: {
      year: String(Number(expireYear) + 2000),
      month: expireMonth,
      formatString: `${expireMonth}/${String(Number(expireYear) + 2000)}`,
    },
    brand: card.card_brand,
    status:
      card.unpaid_services.length > 0
        ? "unpaid"
        : card.is_valid
          ? "valid"
          : "invalid",
    services: card.services.map((service) => ({
      clientId: getClientIdFromServiceId(service.service_id),
      serviceId: service.service_id,
      name: service.service_name,
      planId: service.plan_id,
      planName: service.plan_name,
      unpaid: unpaidServices.has(service.service_id),
    })),
  };
};

export const usePaymentCards = (): usePaymentsType => {
  const payments = useSWR("/account/payments/cards", () => getPaymentCards({}));
  const navigate = useNavigate();

  useEffect(() => {
    if (payments.error) {
      const response = payments.error.response?.data;
      if (response.error === "nkdk_inactive") {
        navigate(path.payments.maintenance);
      } else {
        navigate(path.error.root);
      }
    }
  }, [payments.error]);

  if (payments.isLoading || !payments.data) {
    return {
      mutate: payments.mutate,
      cards: <PaymentsCard[]>[],
      status: "loading",
    } as const;
  }

  return {
    mutate: payments.mutate,
    status: "ok",
    cards: payments.data.data.cards.map(mapToPaymentsCard),
  };
};
