import {
  Button,
  Link2 as Link,
  Modal,
  QuestionCircleIcon,
  Typography2,
  useModal,
} from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ServiceUnavailableHoursModal.module.css";

export const ServiceUnavailableHoursModal: React.FC = () => {
  const { t } = useTranslation();
  const { registerModal, openModal, closeModal } = useModal();
  return (
    <>
      <Link className={`${styles.iconLink} w-fit-content`}>
        <QuestionCircleIcon className={`${styles.icon} mr-1`} />
        <Typography2 as="span" size="14" color={"link"} onClick={openModal}>
          {t("payments.update.modal.service_unavailable_hours.title")}
        </Typography2>
      </Link>
      <Modal
        title={t("payments.update.modal.service_unavailable_hours.title")}
        testId="service-unavailable-hours-modal"
        {...registerModal}
      >
        <Typography2 as="span">
          {t("payments.update.modal.service_unavailable_hours.text")}
        </Typography2>

        <Button
          size={"full"}
          onClick={closeModal}
          data-testid={"button-close-service-unavailable-hours-modal"}
          type="button"
          className={"mt-6"}
        >
          {t("payments.update.button.close")}
        </Button>
      </Modal>
    </>
  );
};
