import { Box, Link2 as Link, Typography2 } from "nikkei-ui";
import type React from "react";
import { useEffect, useRef } from "react";
import styles from "./Header.module.css";

type NavProps = {
  title: string;
  to: string;
  id: string;
};
type NavListProps = {
  navList: NavProps[];
  currentPath: string;
};

type navRefsType = { [key: string]: HTMLLIElement | null };

export const HeaderHorizontalNav: React.FC<NavListProps> = ({
  navList,
  currentPath,
}) => {
  const navRefs = useRef<navRefsType>({});
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentNavRef = navRefs.current[currentPath];
    if (currentNavRef?.scrollIntoView) {
      currentNavRef.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [currentPath]);

  // pc mouse wheel scroll
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const handleWheel = (event: WheelEvent) => {
        if (event.deltaY !== 0) {
          scrollContainer.scrollLeft += event.deltaY;
          event.preventDefault();
        }
      };
      scrollContainer.addEventListener("wheel", handleWheel);
      return () => {
        scrollContainer.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  return (
    <Box
      as="div"
      className={`nid-hidden-tablet ${styles.horizontalNav}`}
      data-testid="mobile-horizontal-nav"
    >
      <Box
        as="nav"
        className={styles.horizontalScrollNav}
        ref={scrollContainerRef}
      >
        <Box as="ul" className={styles.nav}>
          {navList.map((item) => {
            const isCurrent = currentPath === item.id;
            const navItemClass = isCurrent
              ? styles.currentNavItem
              : styles.navItem;
            const color = isCurrent ? "blue120" : undefined;
            return (
              <Box
                key={item.id}
                id={item.id}
                as="li"
                className={navItemClass}
                ref={(el: HTMLLIElement | null) => {
                  navRefs.current[item.id] = el;
                }}
              >
                <Link data-testid={item.id} to={item.to}>
                  <Typography2 size="14" color={color}>
                    {item.title}
                  </Typography2>
                </Link>
                {isCurrent && <Box className={styles.currentBorder} />}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
