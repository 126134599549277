import { Layout } from "@/components/ui/Layout";
import { PaymentsUpdateCardFeature } from "@/features/Payments/Update/PaymentsUpdateCardFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Link2 as Link, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

const PaymentsUpdateCardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.payments_card_update")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} size="14">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography2 as="span" size="14" data-testid="breadcrumb-last-item">
            {t("breadcrumbs.payments_card_update")}
          </Typography2>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider maxAge={1800}>
        <PaymentsUpdateCardFeature />
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsUpdateCardPage;
