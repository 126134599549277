import { path } from "@/routes";
import { encodeStringToBase64Url } from "nid-common";
import { type Payment, getPaymentHistoryDetail } from "nid-common/api/account";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import {
  type PaymentDetail,
  type PaymentDetailChange,
  convertPaymentCardTypeName,
  convertServiceChargesType,
} from "./constants/paymentTypes";

const convertPaymentData = (payment: Payment): PaymentDetail => {
  const detail = payment.payment_detail;
  const changes: PaymentDetailChange[] | undefined = detail?.changes
    ? detail?.changes?.map((change) => ({
        changeType: change.change_type as PaymentDetailChange["changeType"],
        changeTypeName: change.change_type_name,
        changeOrder: change.change_order,
        changeAmount: change.change_amount,
      }))
    : undefined;
  const isTaxRate = payment.tax_rate;
  const isBillingSettlement = payment.service_charges_type === "1";

  return {
    encodedPaymentNo: encodeStringToBase64Url(payment.encrypted_payment_no),
    paymentType: payment.payment_type as PaymentDetail["paymentType"],
    paymentTypeName: payment.payment_type_name,
    paymentHistoryDisplayString: payment.payment_history_display_string,
    paymentCardType: convertPaymentCardTypeName(payment.payment_card_type),
    salesDate: payment.sales_date,
    amount: payment.amount,
    taxRate: isTaxRate ? payment.tax_rate : undefined,
    taxAmount: isTaxRate ? payment.tax_amount : undefined,
    paymentDetail: detail
      ? {
          price: detail.price,
          changes: changes,
        }
      : undefined,
    companyName: payment.company_name,
    invoiceRegistrationNo: payment.invoice_registration_no,
    serviceChargesType: convertServiceChargesType(payment.service_charges_type),
    serviceId: payment.service_id,
    serviceName: payment.service_name,
    planId: isBillingSettlement ? payment.plan_id : undefined,
    planName: isBillingSettlement ? payment.plan_name : undefined,
  };
};

const usePaymentHistoryDetailApi = (
  encodedPaymentNo: string,
): {
  status: "loading" | "ok";
  payment: PaymentDetail | null;
} => {
  const payment = useSWR(
    `/account/payments/histories/${encodedPaymentNo}`,
    () => getPaymentHistoryDetail(encodedPaymentNo),
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (payment.error) {
      const response = payment.error.response?.data;
      if (response.error === "payment_not_found") {
        navigate(path.payments.histories.root, { replace: true });
      } else if (response.error === "nkdk_inactive") {
        navigate(path.payments.maintenance, { replace: true });
      } else {
        navigate(path.error.root, { replace: true });
      }
    }
  }, [payment.error]);

  if (!payment.data) {
    return {
      payment: null,
      status: "loading",
    } as const;
  }

  const paymentData =
    payment.data.data.payments.length === 0
      ? null
      : convertPaymentData(payment.data.data.payments[0]);

  return {
    status: "ok",
    payment: paymentData,
  } as const;
};

export const usePaymentHistoryDetailFeature = (encodedPaymentNo: string) => {
  const { status, payment } = usePaymentHistoryDetailApi(encodedPaymentNo);

  if (status === "loading") {
    return { status: "loading" } as const;
  }

  if (status === "ok" && !payment) {
    return { status: "empty" } as const;
  }

  return {
    status: "ok",
    payment: payment,
  } as const;
};
