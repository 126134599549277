import { I18nextProvider } from "react-i18next";
import { RouterProvider, type RouterProviderProps } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import i18n from "./i18n/i18n";

const App = (props: RouterProviderProps) => (
  <SWRConfig
    value={{
      provider: () => new Map(),
      errorRetryCount: 3,
      errorRetryInterval: 100,
    }}
  >
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <RouterProvider {...props} />
      </I18nextProvider>
    </HelmetProvider>
  </SWRConfig>
);

export default App;
