import type React from "react";

import { PinCodeField } from "nid-common";
import {
  Box,
  Button,
  FlashMessage,
  FormControl,
  Link2 as Link,
  SpinnerIcon,
  Typography2,
} from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { usePersonalEmailChangeConfirmFeature } from "@/features/Personal/EmailChange/hooks/usePersonalEmailChangeConfirmFeature";

export const PersonalEmailChangeConfirmFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    email,
    onFill,
    isSubmitting,
    errorId,
    onResend,
    isResending,
    sendEmail,
    onBack,
  } = usePersonalEmailChangeConfirmFeature();
  if (!email) return <></>;
  return (
    <>
      <ArticleTitle
        testId="article-personal-email-change-confirm"
        title={t("personal.edit.email_change.confirm.title")}
      >
        <Typography2 as="p" className="nid-article-description">
          <Trans
            t={t}
            i18nKey="personal.edit.email_change.confirm.description"
            shouldUnescape={true}
            values={{ email }}
            components={[
              <Typography2 key="email" as="span" bold data-dd-privacy="mask" />,
            ]}
          />
        </Typography2>
      </ArticleTitle>
      <form>
        <Box
          as="section"
          className="nid-section nid-section-without-background"
        >
          <Box className="nid-section-inner">
            <Box className="nid-otp-container">
              <PinCodeField
                className="mb-1"
                valueLength={6}
                isLoading={isSubmitting}
                onFill={onFill}
              />

              <FormControl.Validation
                className="nid-error-field"
                status={errorId ? "error" : undefined}
                data-testid="error-otp"
              >
                {errorId ? t(errorId) : undefined}
              </FormControl.Validation>
            </Box>

            {!isSubmitting && sendEmail ? (
              <FlashMessage variant={"success"}>
                <span className="p-3">
                  {t("personal.edit.email_change.confirm.text.resendSuccess")}
                </span>
              </FlashMessage>
            ) : (
              <Box className="nid-item-center nid-typography-type--button form-action-width">
                <Link
                  onClick={!isResending ? onResend : undefined}
                  data-testid="button-resent"
                  className={
                    isSubmitting
                      ? "nid-typography-block"
                      : "nid-button-text-submit"
                  }
                >
                  <Typography2 color={isResending ? "gray90" : "link"}>
                    {t("personal.edit.email_change.confirm.button.resend")}
                    {isResending && <SpinnerIcon insideTypography />}
                  </Typography2>
                </Link>
              </Box>
            )}
            <Box className="nid-item-center mt-6">
              <Button
                size="full"
                variant="border"
                type="button"
                onClick={onBack}
                data-testid="button-back"
              >
                {t("personal.edit.email_change.confirm.button.back")}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};
