import type React from "react";

import { Box, Link2 as Link, LinkButton, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import styles from "./NikkeiServiceList.module.css";

type Props = {
  services: {
    clientId: string;
    serviceName: string;
    serviceLink?: string;
    settingLink?: string;
    hasSettingPage?: boolean;
  }[];
};

export const NikkeiServiceList: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="nid-section nid-section-overflow">
      <Box
        className="nid-section-inner-gap0"
        data-testid="nikkei-services-list"
      >
        {props.services.map((service, index) => {
          return (
            <Box key={service.serviceName}>
              <Box className={`${styles.item}`}>
                {service.serviceLink ? (
                  <Link
                    href={service.serviceLink}
                    data-testid={`service-name-${service.clientId}`}
                    newTab
                  >
                    {service.serviceName}
                  </Link>
                ) : (
                  <Typography2>{service.serviceName}</Typography2>
                )}
                {service.settingLink && (
                  <LinkButton
                    to={
                      service.hasSettingPage ? service.settingLink : undefined
                    }
                    href={
                      !service.hasSettingPage ? service.settingLink : undefined
                    }
                    variant="border"
                    className={`${styles.linkButton} ml-4`}
                    newTab={!service.hasSettingPage}
                    data-testid={`setting-link-${service.clientId}`}
                  >
                    {t("services.list.button.settings")}
                  </LinkButton>
                )}
              </Box>
              {index !== props.services.length - 1 && (
                <hr className="nid-separator" />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
