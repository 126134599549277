import { useTranslation } from "react-i18next";

import { Outlet, useLocation } from "react-router-dom";

import { Layout } from "@/components/ui/Layout";
import { BackupCodeProvider } from "@/providers/SecurityBackupCodeProvider";
import { path, enableSecurityPasskey } from "@/routes";

import { Link2 as Link, Typography2 } from "nikkei-ui";

export const SecurityMfaPageWrapper = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isBackupCodePage = pathname === path.security.mfa.backupCode;

  const layoutClass = isBackupCodePage
    ? "security-layout security-backupcode-layout"
    : "security-layout";
  const breadcrumbText = isBackupCodePage
    ? "breadcrumbs.security_mfa_backup_code"
    : "breadcrumbs.security_mfa_enabled";
  return (
    <Layout
      title={t(breadcrumbText)}
      layoutClassName={layoutClass}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.security.root} size="14">
            {t("breadcrumbs.security")}
          </Link>
          {isBackupCodePage ? (
            <Link to={path.security.mfa.root} size="14">
              {t("breadcrumbs.security_mfa_enabled")}
            </Link>
          ) : null}
          <Typography2 as="span" size="14">
            {t(breadcrumbText)}
          </Typography2>
        </>
      }
      description={
        enableSecurityPasskey
          ? t("security.description")
          : t("security.old_description")
      }
    >
      <BackupCodeProvider>
        <Outlet />
      </BackupCodeProvider>
    </Layout>
  );
};
