import { Box, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

import PasskeyBiometricAuth from "@/components/icon/PasskeyBiometricAuth.png";
import PasskeyInvalidPassword from "@/components/icon/PasskeyInvalidPassword.png";
import PasskeyShield from "@/components/icon/PasskeyShield.png";

import styles from "./Passkey.module.css";

type PasskeyBenefitsProps = {
  children: React.ReactNode;
};

export const PasskeyBenefits: React.FC<PasskeyBenefitsProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.benefits}>
      <img
        src={PasskeyBiometricAuth}
        alt={t("security.passkey.title")}
        width={108}
      />
      <Box className="nid-section-inner">
        <Box className={styles.benefitsItemRow}>
          <img
            src={PasskeyShield}
            alt={t("security.passkey.benefit.security.title")}
          />
          <Box className="nid-column-item-start">
            <Typography2 size="16" bold>
              {t("security.passkey.benefit.security.title")}
            </Typography2>
            <Typography2 size="13">
              {t("security.passkey.benefit.security.description")}
            </Typography2>
          </Box>
        </Box>

        <Box className={styles.benefitsItemRow}>
          <img
            src={PasskeyInvalidPassword}
            alt={t("security.passkey.benefit.simplicity.title")}
          />
          <Box className="nid-column-item-start">
            <Typography2 size="16" bold>
              {t("security.passkey.benefit.simplicity.title")}
            </Typography2>
            <Typography2 size="13">
              {t("security.passkey.benefit.simplicity.description")}
            </Typography2>
          </Box>
        </Box>
      </Box>
      <Box className={styles.benefitsPassKeyCreate}>{children}</Box>
    </Box>
  );
};
