import type React from "react";

import { Box, Link2 as Link, LinkButton, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "./NikkeiBpServiceList.module.css";

type Props = {
  settingLink?: string;
  services: {
    serviceName: string;
    serviceLink: string | undefined;
  }[];
};

export const NikkeiBpServiceList: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="nid-section nid-section-overflow">
      <Box className="nid-section-inner-gap0" data-testid="bp-services-list">
        <Box className={styles.header}>
          <Typography2 size="16" bold>
            {t("services.list.bp")}
          </Typography2>
          {props.settingLink && (
            <LinkButton
              to={props.settingLink}
              variant="border"
              className="ml-4"
              data-testid="setting-link-bp"
              newTab
            >
              {t("services.list.button.settings")}
            </LinkButton>
          )}
        </Box>
        <Typography2 color="gray90" size="14" className="mb-2">
          {t("services.list.bp_description")}
        </Typography2>
        {props.services.map((service, index) => {
          return (
            <Box key={service.serviceName}>
              <Box className="my-3">
                {service.serviceLink ? (
                  <Link href={service.serviceLink} newTab>
                    {service.serviceName}
                  </Link>
                ) : (
                  service.serviceName
                )}
              </Box>

              {index !== props.services.length - 1 && (
                <hr className="nid-separator" />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
