import { useContractInfoProvider } from "@/features/Services/Contract/Cancel/ContractInfoProvider";
import { useRelyingPartyDisplaySettings } from "@/hooks/useRelyingPartyDisplaySettings";
import { path } from "@/routes";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContractCancel } from "./useContractCancel";

type ContractCancelInput = {
  choices: boolean[];
  textEnabled: boolean;
  text: string;
};

export const useContractCancelFeature = () => {
  const navigate = useNavigate();
  const { status, clientId, cancelCheck, cancelSurvey, contract } =
    useContractCancel();
  const { setContractInfo } = useContractInfoProvider();
  const { status: displaySettingsStatus } = useRelyingPartyDisplaySettings(
    clientId || "",
  );

  const { getValues, setValue, register, watch } = useForm<ContractCancelInput>(
    {
      defaultValues: {
        choices: [],
        text: "",
        textEnabled: false,
      },
    },
  );

  useEffect(() => {
    if (cancelSurvey?.result) {
      setValue("choices", cancelSurvey.result.choices);
      setValue("text", cancelSurvey.result.text);
      setValue("textEnabled", Boolean(cancelSurvey.result.text));
    }
  }, []);

  useEffect(() => {
    if (status !== "ok") return;
    if (!cancelCheck.result) {
      cancelCheck.check().catch(() => {
        navigate(path.error.root, { replace: true });
      });
    }
  }, [cancelCheck?.result, status]);

  useEffect(() => {
    if (displaySettingsStatus === "not_found") {
      navigate(path.services.root, {
        replace: true,
      });
      return;
    }
    if (displaySettingsStatus === "error") {
      navigate(path.error.root, {
        replace: true,
      });
      return;
    }
    if (status === "contract_not_found") {
      navigate(path.services.root, {
        replace: true,
      });
      return;
    }
    if (status !== "ok") return;
    if (cancelCheck.result?.cancelable === false) {
      navigate(path.services.contract.root(clientId), {
        replace: true,
      });
      return;
    }
    if (!cancelSurvey.hasSurvey && cancelCheck.result) {
      submit();
      navigate(path.services.contract.cancel.confirm(clientId), {
        replace: true,
      });
      return;
    }
  }, [cancelSurvey, cancelCheck, clientId, status, displaySettingsStatus]);

  if (
    status === "loading" ||
    status === "contract_not_found" ||
    cancelCheck.result?.cancelable === false
  ) {
    return { status: "loading" } as const;
  }

  const submit = async () => {
    setContractInfo({
      clientId: clientId,
      serviceName: contract.contractDetail.serviceName,
      planName: contract.contractDetail.planName,
      isPaidPlan: contract.contractDetail.isPaidPlan,
    });
    try {
      await cancelSurvey.mutateResult({
        choices: getValues("choices"),
        text: getValues("text"),
      });
      navigate(path.services.contract.cancel.confirm(clientId), {
        replace: true,
      });
    } catch (_) {
      cancelCheck.clear();
      cancelSurvey.clear();
      navigate(path.error.root, { replace: true });
    }
  };

  if (!cancelSurvey.hasSurvey) {
    return { status: "no_survey" } as const;
  }

  return {
    status: "ok",
    submit,
    contract: {
      clientId: clientId,
      isPaid: contract.contractDetail.isPaidPlan,
      isFreeTrial: contract.contractDetail.isFreeTrial,
      serviceName: contract.contractDetail.serviceName,
      planName: contract.contractDetail.planName || "",
    },
    survey: cancelSurvey.hasSurvey
      ? {
          choices: cancelSurvey.choices,
          text: {
            visible: cancelSurvey.hasText,
            enabled: watch("textEnabled"),
          },
        }
      : undefined,
    registers: {
      choices: (index: number) => register(`choices.${index}`),
      textEnabled: register("textEnabled", {
        onChange: (e) => {
          if (!e.target.checked) {
            setValue("text", "");
          }
        },
      }),
      text: register("text"),
      textValue: watch("text"),
    },
  };
};
