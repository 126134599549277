import {
  ArrowRightIcon,
  Box,
  InfoCircleFillIcon,
  Link2 as Link,
  Typography2,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";
import styles from "./Notifications.module.css";

type NotificationItem = {
  title: string;
  published_at: string;
  href?: string;
  to?: string;
  testId: string;
  newTab?: boolean;
};

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const notifications: NotificationItem[] = [
    {
      title: t("account_top.notifications.1.title"),
      href: `${import.meta.env.VITE_ORIGIN}/nl/20250114.html`,
      published_at: t("account_top.notifications.1.published_at", {
        year: 2025,
        month: 1,
        day: 14,
      }),
      testId: "notification-item-1",
      newTab: true,
    },
  ];

  return (
    <Box as="section" className={styles.notificationSection}>
      <Box className="nid-row nid-item-gap4 pl-4">
        <InfoCircleFillIcon width={16} height={16} role="img" />
        <Typography2
          as="h4"
          bold
          lineHeight="150"
          data-testid="notification-title"
        >
          {t("account_top.title.notifications")}
        </Typography2>
      </Box>
      <Box
        as="ul"
        className="nid-column-item-initial nid-body-nav-container w-full"
        data-testid="notification-item"
      >
        {notifications.map((item, index) => {
          return (
            <NotificationItem
              key={item.title}
              label={item.title}
              publishedLabel={item.published_at}
              href={item.href}
              newTab={item.newTab || false}
              noBorder={index === notifications.length - 1}
              testId={item.testId}
              linkSpace
            />
          );
        })}
      </Box>
    </Box>
  );
};

type ItemProps = {
  label: string;
  publishedLabel?: string;
  href?: string;
  to?: string;
  testId?: string;
  noBorder?: boolean;
  newTab?: boolean;
  linkSpace?: boolean;
};

const NotificationItem: React.FC<ItemProps> = ({
  label,
  publishedLabel,
  href,
  to,
  testId,
  noBorder,
  newTab,
}) => {
  return (
    <Box as="li">
      <Link
        href={href}
        to={to}
        className={styles.itemLink}
        data-testid={testId}
        newTab={newTab}
      >
        <Box
          className={
            noBorder ? styles.itemContents : styles.itemContentsWithBorder
          }
        >
          <Box className="nid-column-item-initial nid-item-gap4">
            <Typography2 as="p" color="link" size="14" lineHeight="150">
              {label}
            </Typography2>
            {publishedLabel && (
              <Typography2 as="span" size="13" color="gray120" lineHeight="165">
                {publishedLabel}
              </Typography2>
            )}
          </Box>

          <Box className="nid-nav-icon">
            <ArrowRightIcon />
          </Box>
        </Box>
      </Link>
    </Box>
  );
};
