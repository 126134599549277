import { useEffect, useState } from "react";

import type { AxiosError } from "axios";
import type { ParseKeys } from "i18next";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  type SecurityMfaEmailValues,
  useEmailMfaSetupToken,
} from "@/features/Security/Mfa/Email/SecurityMfaEmailFormProvider";
import { useBackupCode } from "@/providers/SecurityBackupCodeProvider";
import { path } from "@/routes";
import type { ChangeLoginIdConfirmErrorResponse } from "nid-common/api/account";
import {
  postMfaSetupEmailConfirm,
  postMfaSetupEmailStart,
} from "nid-common/api/account";

export const useSecurityMfaEmailConfirmFeature = () => {
  const { getValues } = useFormContext<SecurityMfaEmailValues>();
  const {
    mfaSetupToken,
    setMfaSetupToken,
    removeMfaSetupToken,
    emailConfirmValue: email,
  } = useEmailMfaSetupToken();
  const { setBackupCode } = useBackupCode();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<ParseKeys | undefined>(undefined);

  const [isResending, setIsResending] = useState(false);
  const [sendEmail, setSendEmail] = useState<"success" | undefined>(undefined);

  useEffect(() => {
    if (sendEmail) {
      setTimeout(() => {
        setSendEmail(undefined);
      }, 5000);
    }
  }, [sendEmail]);

  useEffect(() => {
    // URL直アクセス対策
    if (!email && !mfaSetupToken) {
      navigate(path.root, { replace: true });
    }
  }, [email, mfaSetupToken]);

  const onFill = async (values: string[]): Promise<void> => {
    try {
      setIsSubmitting(true);
      const response = await postMfaSetupEmailConfirm({
        mfa_setup_token: mfaSetupToken,
        otp: values.join(""),
      });
      setBackupCode(response.data.backup_codes);
      if (removeMfaSetupToken) removeMfaSetupToken();
      navigate(path.security.mfa.complete);
    } catch (e) {
      const response = (e as AxiosError<ChangeLoginIdConfirmErrorResponse>)
        .response;
      if (response?.status === 400 && response.data.error === "invalid_otp") {
        setErrorId("security.mfa.email.confirm.errors.invalid");
      } else {
        navigate(path.error.root);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = async (_: string[]): Promise<void> => {
    setErrorId(undefined);
  };

  const onResend = async (): Promise<void> => {
    setIsResending(true);
    const timeout = new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    try {
      const response = await postMfaSetupEmailStart({
        email: getValues().emailSameAsLoginId === "true" ? undefined : email,
      });
      setMfaSetupToken(response.data.mfa_setup_token);
    } catch (_) {
      navigate(path.error.root);
    }
    await timeout;
    setSendEmail("success");
    setIsResending(false);
  };

  return {
    email,
    onFill,
    onChange,
    isSubmitting,
    errorId,
    onResend,
    isResending,
    sendEmail,
  };
};
