import type { AxiosError } from "axios";
import { useContext } from "react";
import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import type { PersonalEmailChangeValues } from "@/features/Personal/EmailChange/PersonalEmailChangeFormProvider";
import { path } from "@/routes";
import { useUserStatus } from "nid-common";
import {
  type EmailChangeStartErrorResponse,
  postChangeLoginIdStart,
} from "nid-common/api/account";
import { PersonalEmailConfirmContext } from "../PersonalEmailChangeFormProvider";

export const usePersonalEmailChangeInputFeature = () => {
  const { status, error, loginId } = useUserStatus();
  const { t } = useTranslation();
  const [, setEmailConfirm] = useContext(PersonalEmailConfirmContext);
  const schema = yup
    .object()
    .required()
    .shape({
      email: yup
        .string()
        .required(t("personal.edit.errors.required"))
        .email(t("personal.edit.email_change.errors.invalid_email")),
    });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useFormContext<PersonalEmailChangeValues>();
  const navigate = useNavigate();
  const errorMessage = t("personal.edit.email_change.errors", {
    returnObjects: true,
  });

  const handleChangeEmail: SubmitHandler<PersonalEmailChangeValues> = async (
    data: PersonalEmailChangeValues,
  ) => {
    try {
      await postChangeLoginIdStart({
        login_id: data.email,
      });
      setEmailConfirm(data.email);
      navigate(path.personal.emailChange.confirm);
    } catch (e) {
      const response = (e as AxiosError<EmailChangeStartErrorResponse>)
        .response;
      if (response?.data?.error && response.data.error === "invalid_email") {
        setError("email", { message: errorMessage.invalid_email });
      } else if (
        response?.data?.error &&
        response.data.error === "already_registered"
      ) {
        setError("email", { message: errorMessage.already_registered });
      } else {
        navigate(path.error.root);
      }
    }
  };

  const registers = {
    email: register("email", {
      validate: async (value: string) => {
        try {
          await schema.validate({ email: value });
        } catch (e) {
          return (e as yup.ValidationError).message;
        }
        return undefined;
      },
    }),
  };

  if (status === "loading") {
    return { status: "loading" } as const;
  }
  if (status === "error") {
    return { status: "error", error } as const;
  }

  return {
    status: "ok",
    email: loginId,
    handleSubmit: handleSubmit(handleChangeEmail),
    registers,
    errors,
    buttonEnabled: !isSubmitting && Object.keys(errors).length === 0,
  };
};
