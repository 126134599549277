import { useContractDetail } from "@/hooks/useContractDetail";
import { path } from "@/routes";
import { isAxiosError } from "axios";
import {
  getContractCancelCheck,
  getContractCancelQuestionnaire,
} from "nid-common/api/account";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { AtlasTracking } from "../../../../../../../../packages/nid-common";

export type ContractCancelCheckResult =
  | {
      service: {
        id: string;
        name: string;
      };
      plan: {
        id: string;
        name: string;
      };
      cancelable: boolean;
      expiration?: {
        year: number;
        month: number;
        date: number;
      };
      penalty:
        | {
            amount: number;
            date: string;
          }
        | undefined;
    }
  | "nkdk_inactive"
  | "error";

export type Choice = {
  label?: string;
  index: number;
};

const parseNkdkDate = (date: string) => {
  return {
    year: Number(date.slice(0, 4)),
    month: Number(date.slice(4, 6)),
    date: Number(date.slice(6, 8)),
  };
};

const useContractCancelCheck = (clientId: string, planId: string) => {
  const { data: result, mutate: mutateResult } =
    useSWR<ContractCancelCheckResult>(
      `/account/service/${clientId}/contract:check`,
      null,
    );

  const check = async () => {
    if (!planId) return;
    try {
      const ret = await getContractCancelCheck(clientId, planId);

      if (ret.data.termination_info_group.length !== 1) {
        throw new Error("Invalid response: termination_info_group");
      }

      const v = ret.data.termination_info_group[0];
      const cancelCheckResult = {
        service: {
          id: v.service_id,
          name: v.service_name,
        },
        plan: {
          id: v.plan_id,
          name: v.plan_name,
        },
        cancelable: v.request_status === "0",
        expiration: v.apply_date ? parseNkdkDate(v.apply_date) : undefined,
        penalty: v.discount_penalty_info
          ? {
              amount: v.discount_penalty_info.price,
              date: v.discount_penalty_info.payment_date,
            }
          : undefined,
      };
      await mutateResult(cancelCheckResult);
    } catch (e) {
      if (
        isAxiosError(e) &&
        e.response?.status === 503 &&
        e.response?.data.error === "nkdk_inactive"
      ) {
        await mutateResult("nkdk_inactive");
        return;
      }
      AtlasTracking.unknownError(e);
      await mutateResult("error");
    }
  };

  const clear = () => {
    mutateResult(undefined);
  };

  if (result === "nkdk_inactive") {
    return { status: "nkdk_inactive", check, clear } as const;
  }

  if (result === "error") {
    return { status: "error", check, clear } as const;
  }

  return {
    status: "ok",
    check,
    clear,
    result,
  } as const;
};

export type ContractCancelSurveyResult = {
  choices: boolean[];
  text: string;
};

export const useContractCancelSurvey = (clientId: string, planId: string) => {
  const ret = useSWRImmutable(
    `/account/service/${clientId}/contract/cancel-questionnaire?plan_id=${planId}`,
    () =>
      planId ? getContractCancelQuestionnaire(clientId, planId) : undefined,
  );

  const { data: result, mutate: mutateResult } =
    useSWR<ContractCancelSurveyResult>(
      `/account/service/${clientId}/contract:result`,
      undefined,
    );

  if (
    ret.error &&
    ret.error.status === 503 &&
    ret.error.response.data.error === "nkdk_inactive"
  ) {
    return { status: "nkdk_inactive" } as const;
  }

  if (ret.error) {
    return { status: "error" } as const;
  }

  if (ret.isLoading || !ret.data || !clientId || !planId) {
    return { status: "loading" } as const;
  }

  const info = ret.data.data.close_questionnaire_info;
  const choices = info
    ? ([
        { label: info.question1, index: 0 },
        { label: info.question2, index: 1 },
        { label: info.question3, index: 2 },
        { label: info.question4, index: 3 },
        { label: info.question5, index: 4 },
        { label: info.question6, index: 5 },
        { label: info.question7, index: 6 },
        { label: info.question8, index: 7 },
        { label: info.question9, index: 8 },
        { label: info.question10, index: 9 },
      ] as Choice[])
    : [];

  const clear = () => {
    mutateResult(undefined);
  };

  return {
    status: "ok",
    choices,
    hasText: info?.question_text === "1",
    hasSurvey: info?.question_text === "1" || choices.length > 0,
    result:
      result ??
      ({
        choices: new Array(choices.length).fill(false),
        text: "",
      } as ContractCancelSurveyResult),
    mutateResult,
    clear,
  } as const;
};

export const useContractCancel = () => {
  const { clientId: rawClientId } = useParams();
  const clientId = rawClientId || "";
  const navigate = useNavigate();

  const contract = useContractDetail(clientId);
  const planId = contract.contractDetail?.planId || "";

  const cancelSurvey = useContractCancelSurvey(clientId, planId);
  const cancelCheck = useContractCancelCheck(clientId, planId);

  useEffect(() => {
    if (
      contract.status === "nkdk_inactive" ||
      cancelSurvey.status === "nkdk_inactive" ||
      cancelCheck.status === "nkdk_inactive"
    ) {
      navigate(path.services.contract.maintenance(clientId || ""), {
        replace: true,
      });
      return;
    }

    if (
      contract.status === "error" ||
      cancelSurvey.status === "error" ||
      cancelCheck.status === "error"
    ) {
      navigate(path.error.root, { replace: true });
      return;
    }
  }, [contract, clientId, cancelSurvey, cancelCheck]);

  if (!clientId || contract?.status === "contract_not_found") {
    return {
      status: "contract_not_found",
      clientId,
    } as const;
  }

  if (
    !contract.contractDetail ||
    !cancelSurvey.choices ||
    !clientId ||
    !contract ||
    cancelCheck.status === "error" ||
    cancelCheck.status === "nkdk_inactive"
  ) {
    return {
      status: "loading",
      clientId,
    } as const;
  }

  return {
    status: "ok",
    clientId,
    planId,
    contract,
    cancelSurvey,
    cancelCheck,
  } as const;
};

export const useContractAdjustTextareaHeight = (watchedText: string) => {
  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    const textarea = document.querySelector(
      "textarea[name='text']",
    ) as HTMLTextAreaElement;
    if (textarea) {
      adjustTextareaHeight(textarea);
    }
  }, [watchedText]);
};
