import type * as React from "react";

import { t } from "i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaDisableFeature } from "@/features/Security/Mfa/Disable/SecurityMfaDisableFeature";
import { LoginWallProvider } from "nid-common";
import { Typography2 } from "nikkei-ui";

const SecurityMfaDisablePage: React.FC = () => {
  return (
    <LoginWallProvider maxAge={600}>
      <ArticleTitle
        title={t("security.mfa.disable.title")}
        testId="article-security-mfa-disable"
      >
        <Typography2 as="p" className="nid-article-description">
          {t("security.mfa.disable.description")}
        </Typography2>
      </ArticleTitle>
      <SecurityMfaDisableFeature />
    </LoginWallProvider>
  );
};

export default SecurityMfaDisablePage;
