import { Tag } from "@/components/ui/Tag";
import { Box, LinkButton, Typography2 } from "nikkei-ui";
import { AccountKeyIcon } from "nikkei-ui/components/Icons/AccountKeyIcon/AccountKeyIcon";
import type React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Passkey.module.css";

export type AtDateTime = {
  year: number;
  month: number;
  date: number;
  hour: number;
  minute: number;
};

type DescriptionInnerProps = {
  label: string;
  datetime: AtDateTime;
};

const DescriptionInner: React.FC<DescriptionInnerProps> = ({
  label,
  datetime,
}) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.itemCardDescriptionInner}>
      <Typography2 size="14" color="gray90">
        {label}
      </Typography2>
      <Typography2 size="14" color="gray90">
        {t("security.passkey.datetime", {
          year: datetime.year,
          month: datetime.month,
          date: datetime.date,
          hour: datetime.hour,
          minute: String(datetime.minute).padStart(2, "0"),
        })}
      </Typography2>
    </Box>
  );
};

export type PasskeyItemProps = {
  title?: string;
  createdAtDateTime: AtDateTime;
  lastUsedAtDateTime?: AtDateTime;
  backupEligible: boolean;
  handleClickRevokePassKey?: () => void;
  testId?: string;
};

export const PasskeyItem: React.FC<PasskeyItemProps> = ({
  title,
  createdAtDateTime,
  lastUsedAtDateTime,
  backupEligible,
  handleClickRevokePassKey,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.itemCard} data-testid={testId}>
      <Box className={styles.itemCardHeader}>
        <Box className="nid-row">
          <Box className="nid-icon-container-large mr-2">
            <AccountKeyIcon color={"blue120"} />
          </Box>
          <Typography2 as="h3" size="18" lineHeight="150" bold color="blue120">
            {title ||
              t("security.passkey.passkey_name", {
                year: createdAtDateTime.year,
                month: String(createdAtDateTime.month).padStart(2, "0"),
                date: String(createdAtDateTime.date).padStart(2, "0"),
              })}
          </Typography2>
        </Box>
        {handleClickRevokePassKey && (
          <LinkButton
            to="#"
            data-testid={`${testId}-delete-button`}
            variant="border"
            size="sm"
            className={styles.changeButton}
            onClick={handleClickRevokePassKey}
          >
            {t("security.passkey.button.delete")}
          </LinkButton>
        )}
      </Box>

      <Box className="nid-column-item-start">
        <DescriptionInner
          label={t("security.passkey.created_at")}
          datetime={createdAtDateTime}
        />
        {lastUsedAtDateTime && (
          <DescriptionInner
            label={t("security.passkey.last_used_at")}
            datetime={lastUsedAtDateTime}
          />
        )}
        {!backupEligible && (
          <Tag
            variant="default"
            text={t("security.passkey.tag.text")}
            className={styles.itemCardDescriptionTag}
          />
        )}
      </Box>
    </Box>
  );
};
