import * as React from "react";

import {
  Box,
  Checkbox,
  Link2 as Link,
  QuestionCircleIcon,
  Typography2,
} from "nikkei-ui";

import { useTranslation } from "react-i18next";
import RequiredTag from "../../Tag/RequiredTag";

type InputWithRefProps = React.ComponentPropsWithRef<"input">;

type Props = {
  testId?: string;
} & InputWithRefProps;

export const NikkeiResearchMonitorField = React.forwardRef<
  HTMLInputElement,
  Props
>(({ testId, ...props }, ref) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography2 as="legend" bold className="mb-2">
        {t("attributes.nikkei_research_monitor")}
        <RequiredTag on={false} />
      </Typography2>
      <Box className="mb-2">
        <Checkbox
          data-testid={`${testId}-nikkei-research-monitor`}
          label={t("attribute_values.nikkei_research_monitor.1")}
          id="nikkei-research-monitor"
          ref={ref}
          {...props}
        />
      </Box>
      <Box className="pt-2 pb-2 pr-1 pl-1 mb-9">
        <Link
          href={import.meta.env.VITE_URL_NIKKEI_RESEARCH_MONITOR}
          className="nid-row w-fit-content"
          size="16"
          newTab
        >
          <QuestionCircleIcon /> 日経IDリサーチモニターとは
        </Link>
      </Box>
    </>
  );
});

NikkeiResearchMonitorField.displayName = "Nid.Form.NikkeiResearchMonitorField";
