import { useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  type PersonalSurveyFormValues,
  buildUserInfoPatch,
} from "@/features/Personal/Survey/PersonalSurveyFormProvider";
import {
  type PatchUserInfoError,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { path } from "@/routes";

export const usePersonalSurveyInputFeature = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } =
    useFormContext<PersonalSurveyFormValues>();
  const { patch } = usePatchUserInfo();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string>();

  const handlePatchUserInfo: SubmitHandler<PersonalSurveyFormValues> = async (
    input: PersonalSurveyFormValues,
  ): Promise<void> => {
    try {
      await patch(buildUserInfoPatch(input), true);
      setValue("inputDone", true);
      navigate(path.personal.survey.confirm);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const errors = apiError ? { api: { message: apiError } } : undefined;

  return {
    status: "ok",
    register,
    handleSubmit: handleSubmit(handlePatchUserInfo),
    errors,
    buttonEnabled: errors === undefined,
  };
};
