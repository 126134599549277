import type React from "react";

import { Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { ServiceContractCancelAlertFeature } from "@/features/Services/Contract/Cancel/ServiceContractAlertFeature";
import { ServiceContractCancelFeature } from "@/features/Services/Contract/Cancel/ServiceContractCancelFeature";

export const ServiceContractCancelPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ServiceContractCancelAlertFeature />
      <ArticleTitle
        title={t("services.service_contract_cancel.title")}
        testId="article-service-contract-cancel"
      >
        <Typography2 as="p" className="nid-article-description">
          {t("services.service_contract_cancel.description")}
        </Typography2>
      </ArticleTitle>
      <ServiceContractCancelFeature />
    </>
  );
};

export default ServiceContractCancelPage;
