import {
  ArrowRightIcon,
  Box,
  Link2 as Link,
  Typography2,
  type Typography2Props,
} from "nikkei-ui";

import styles from "./Navigation.module.css";

export type NavigationItemState = {
  destinationAfterPaymentsUpdate: string;
};

interface NavigationItemProps {
  label: string;
  href?: string;
  to?: string;
  size?: Typography2Props["size"];
  testId?: string;
  noBorder?: boolean;
  newTab?: boolean;
  state?: NavigationItemState;
  linkSpace?: boolean;
}

export const NavigationItem = ({
  label,
  href,
  to,
  size = "16",
  testId,
  noBorder,
  newTab,
  state,
  linkSpace = false,
}: NavigationItemProps): JSX.Element => {
  return (
    <Link
      href={href}
      to={to}
      linkClassName={linkSpace ? styles.navItemLink : styles.navItemLinkNoSpace}
      data-testid={testId}
      newTab={newTab}
      state={state}
    >
      <Box
        className={noBorder ? "nid-body-nav-item--noborder" : styles.navItem}
      >
        <Typography2 as="span" size={size} color="link">
          {label}
        </Typography2>
        <Box className="nid-nav-icon">
          <ArrowRightIcon />
        </Box>
      </Box>
    </Link>
  );
};
