import type React from "react";

import { Box, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

export const WithdrawCompletePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box className="nid-article-header mb-10">
      <Box className="mb-20">
        <ArticleTitle
          testId="article-withdraw-complete"
          title={t("withdraw.complete.title")}
        >
          <Typography2 as="p" className="nid-article-description">
            {t("withdraw.complete.description")}
          </Typography2>
        </ArticleTitle>
      </Box>
    </Box>
  );
};
