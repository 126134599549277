import type * as React from "react";

import { t } from "i18next";
import { Box, LinkButton, Typography2 } from "nikkei-ui";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { BackButton } from "@/components/ui/BackButton/BackButton";
import { path } from "@/routes";

const SecurityMfaDisableCompletePage: React.FC = () => {
  return (
    <>
      <ArticleTitle
        title={t("security.mfa.disable_complete.title")}
        testId="article-security-mfa-disable-complete"
      >
        <Typography2 as="p" className="nid-article-description">
          {t("security.mfa.disable_complete.description")}
        </Typography2>
      </ArticleTitle>
      <Box className="nid-section-inner">
        <LinkButton
          to={path.security.mfa.root}
          size="full"
          data-testid="button-goto-mfa-top"
        >
          {t("security.mfa.disable_complete.button.goto_mfa_top")}
        </LinkButton>

        <BackButton
          to={path.root}
          buttonText={t("security.mfa.disable_complete.button.back")}
          testId="button-back"
        />
      </Box>
    </>
  );
};

export default SecurityMfaDisableCompletePage;
