import type React from "react";

import { Box, Link2, NikkeiLogo, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { COPYRIGHT_TEXT } from ".";

export interface FooterProps {
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <Box as="footer" className="nid-footer w-full" data-testid="footer">
      {children}
      <Box className="nid-footer-bottom">
        <Box className="nid-copyright">
          <NikkeiLogo />
          <Typography2 as="span" size="12" className="nid-copyright-text">
            {COPYRIGHT_TEXT}
          </Typography2>
        </Box>
      </Box>
    </Box>
  );
};

const FooterNavigation: React.FC<FooterProps> = () => {
  const { t } = useTranslation();
  return (
    <Box className="nid-footer-nav">
      <Box className="nid-footer-nav-item">
        <Link2 href={import.meta.env.VITE_URL_NIKKEI_TERMS_OF_USE} newTab>
          <Typography2 as="span" size="14" color="link">
            {t("layout.footer.terms_of_use")}
          </Typography2>
        </Link2>
      </Box>
      <Box className="nid-footer-nav-item nid-footer-nav-item--line" />
      <Box className="nid-footer-nav-item">
        <Link2 href={import.meta.env.VITE_URL_NIKKEI_PRIVACY_POLICY} newTab>
          <Typography2 as="span" size="14" color="link">
            {t("layout.footer.privacy_policy")}
          </Typography2>
        </Link2>
      </Box>
    </Box>
  );
};

Footer.displayName = "Nid.Footer";
const Root = Footer;
export { Root, Footer, FooterNavigation };
export default Footer;
