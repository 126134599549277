import type React from "react";

import { Link2 as Link, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { Layout } from "@/components/ui/Layout";
import { path } from "@/routes";

export const WithdrawPageWrapper: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.withdraw")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography2 as="span" size="14">
            {t("breadcrumbs.withdraw")}
          </Typography2>
        </>
      }
      description={t("withdraw.description")}
    >
      <Outlet />
    </Layout>
  );
};
