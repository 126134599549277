import type * as React from "react";

import { Link2 as Link, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityFeature } from "@/features/Security/SecurityFeature";
import { path, enableSecurityPasskey } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../components/ui/Layout";

export const SecurityPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.security")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography2 as="span" size="14">
            {t("breadcrumbs.security")}
          </Typography2>
        </>
      }
      description={
        enableSecurityPasskey
          ? t("security.description")
          : t("security.old_description")
      }
    >
      <LoginWallProvider>
        <ArticleTitle title={t("security.title")} testId="article-security">
          <Typography2 as="p" className="nid-article-description">
            {enableSecurityPasskey
              ? t("security.description")
              : t("security.old_description")}
          </Typography2>
        </ArticleTitle>
        <SecurityFeature />
      </LoginWallProvider>
    </Layout>
  );
};
