import type React from "react";

import { clsx } from "clsx";

import { Box } from "../../../foundations/Box";
import { Typography2 } from "../../Typography2";
import style from "./PasswordStrengthChecker.module.css";

/* DISPLAY_NAME */
const DISPLAY_NAME = "Nid.PasswordStrengthChecker";

type PasswordStrengthCheckerProps = {
  score: 0 | 1 | 2 | 3 | 4 | "empty";
  label: string;
  validationText: string;
};

/**
 * PasswordStrengthChecker component
 * @param score
 * @param strengthScoreList
 * @param label
 * @param validationText
 * @constructor
 */
const PasswordStrengthChecker: React.FC<PasswordStrengthCheckerProps> = ({
  score,
  label,
  validationText,
}) => {
  const nColoredMeters = (score: PasswordStrengthCheckerProps["score"]) => {
    switch (score) {
      case "empty":
        return 0;
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return 3;
      case 3:
        return 5;
      case 4:
        return 8;
    }
  };
  const getColor = (
    score: Exclude<PasswordStrengthCheckerProps["score"], "empty">,
  ) => {
    switch (score) {
      case 0:
        return "red90";
      case 1:
        return "red90";
      case 2:
        return "yellow90";
      case 3:
        return "green90";
      case 4:
        return "blue90";
    }
  };

  return (
    <Box className="nid-row">
      <Typography2 as="p" size={"14"}>
        {label}
      </Typography2>
      <Box className={style.text}>
        {score !== "empty" && (
          <Typography2 as="p" size={"14"} bold color={getColor(score)}>
            {validationText}
          </Typography2>
        )}
      </Box>
      <Box className="nid-row">
        {[0, 0, 0, 0, 0, 0, 0, 0].map((_, index) => (
          <Box
            // biome-ignore lint/suspicious/noArrayIndexKey: パスワードレベルメーターは静的な順番のためindexをキーにしても問題ない
            key={index}
            className={clsx(
              style.strength,
              index + 1 <= nColoredMeters(score) && score !== "empty"
                ? style[`strength-${getColor(score)}`]
                : style["strength-default"],
            )}
          />
        ))}
      </Box>
    </Box>
  );
};

PasswordStrengthChecker.displayName = DISPLAY_NAME;
export { PasswordStrengthChecker };
export type { PasswordStrengthCheckerProps };
export default PasswordStrengthChecker;
