import { NoImage } from "@/components/icon/NoImage";
import { Box, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Profile.module.css";

interface ProfileProps {
  userName?: string;
  loginId?: string;
}

export const Profile: React.FC<ProfileProps> = ({ userName, loginId }) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.profile} data-testid="profile">
      <NoImage
        height={"100%"}
        data-testid={"profile-avatar"}
        className={styles.avatar}
      />
      <Box className={styles.textContainer}>
        {userName && loginId ? (
          <>
            <Typography2
              size="20"
              bold
              className={styles.userName}
              data-testid="profile-user-name"
            >
              {userName} {t("account_top.profile.honorific_title")}
            </Typography2>
            <Typography2
              size="14"
              className={styles.loginId}
              data-testid="profile-loginid"
            >
              {loginId}
            </Typography2>
          </>
        ) : loginId ? (
          <Typography2
            size="20"
            bold
            className={styles.userName}
            data-testid="profile-loginid"
          >
            {loginId}
          </Typography2>
        ) : undefined}
      </Box>
    </Box>
  );
};
