import type React from "react";

import { Box, LinkButton, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";

const PaymentsDeleteCompletePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box className="nid-article-header mb-10">
        <Box className="mb-20">
          <ArticleTitle
            testId="article-payments-delete-complete"
            title={t("payments.delete.complete.title")}
          >
            <Typography2 as="p" className="nid-article-description">
              {t("payments.delete.complete.description")}
            </Typography2>
          </ArticleTitle>
        </Box>

        <Box className="nid-article-header-inner">
          <LinkButton to={path.payments.root} data-testid="submit" size="full">
            {t("payments.delete.complete.button.back")}
          </LinkButton>
        </Box>
      </Box>
    </>
  );
};

export default PaymentsDeleteCompletePage;
