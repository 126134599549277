import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import PersonalInfoConfirmFeature from "@/features/Personal/Info/PersonalInfoConfirmFeature";
import { Typography2 } from "nikkei-ui";

const PersonalInfoConfirmPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        testId="article-personal-info-confirm"
        title={t("personal.edit.info.confirm.title")}
      >
        <Typography2 as="p" className="nid-article-description">
          {t("personal.edit.info.confirm.description")}
        </Typography2>
      </ArticleTitle>
      <PersonalInfoConfirmFeature />
    </>
  );
};

export default PersonalInfoConfirmPage;
