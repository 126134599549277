import { useContractCancelCompleteFeature } from "@/features/Services/Contract/Cancel/hooks/useContractCancelCompleteFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { Link2 as Link, LinkButton, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { ContractContent } from "./ContractContent";

export const ServiceContractCancelCompleteFeature: React.FC = () => {
  const { t } = useTranslation();
  const r = useContractCancelCompleteFeature();

  if (r.status === "loading") return <ContentSkeletonLoader />;

  const clientId = r.clientId;

  return (
    <>
      <ContractContent
        contract={{
          service: r.serviceName,
          plan: r.planName,
          expiration: r.expiration,
          isPaidPlan: r.expiration !== undefined,
          note: r.expiration ? (
            <>
              <Typography2 size="16" lineHeight={"165"}>
                {t("services.service_contract_cancel_complete.contract.note1")}
              </Typography2>
              <Typography2 size="16" lineHeight={"165"}>
                {t("services.service_contract_cancel_complete.contract.note2", {
                  year: r.expiration?.year,
                  month: r.expiration?.month,
                  date: r.expiration?.date,
                })}
              </Typography2>
            </>
          ) : undefined,
        }}
      />

      {r.isPaidPlan ? (
        <>
          <LinkButton
            to={path.services.service(clientId)}
            className={"mt-6"}
            size={"full"}
          >
            {t("services.service_contract_cancel_complete.button.service")}
          </LinkButton>

          <Link
            to={path.services.contract.root(clientId)}
            className={"nid-item-center mt-7"}
          >
            {t("services.service_contract_cancel_complete.button.contract")}
          </Link>
        </>
      ) : (
        <LinkButton to={path.services.root} className={"mt-6"} size={"full"}>
          {t("services.service_contract_cancel_complete.button.services")}
        </LinkButton>
      )}
    </>
  );
};
