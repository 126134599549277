import { Box, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

type PaymentPrintMessageProps = {
  year: number;
  month: number;
};

export const PaymentPrintMessage = ({
  year,
  month,
}: PaymentPrintMessageProps) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="hidden print-visible">
      <Box className="nid-section-inner-gap0">
        <Typography2 as="p" size="14">
          {t("payments.histories.detail.print.text")}
        </Typography2>
        <Typography2 as="p" size="14">
          {t("payments.histories.detail.print.message", {
            year: year,
            month: month,
          })}
        </Typography2>
      </Box>
    </Box>
  );
};

export const PaymentPrintCautionMessage = () => {
  const { t } = useTranslation();
  return (
    <Box className="hidden print-visible">
      <Box className="nid-section-inner-gap0">
        <Typography2 as="p" size="12">
          {t("payments.histories.detail.print.caution")}
        </Typography2>
      </Box>
    </Box>
  );
};
