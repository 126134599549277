import type React from "react";
import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import {
  type PersonalOfficeFormValues,
  buildUserInfoPatch,
} from "@/features/Personal/Office/PersonalOfficeFormProvider";
import {
  type PatchUserInfoError,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { path } from "@/routes";
import {
  toFullWidth,
  toHalfWidth,
  useAutoCompleteCompanyName,
  useCompanyTypeMaster,
} from "nid-common";

type FieldName =
  | "companyCountryFlag"
  | "domesticCompany.companyName"
  | "domesticCompany.companyBusinessUnit"
  | "domesticCompany.companyZipCode"
  | "domesticCompany.companyAddress"
  | "domesticCompany.companyTel"
  | "domesticCompany.companyTypeCodeFront"
  | "domesticCompany.companyTypeCodeBack"
  | "overseasCompany.companyName"
  | "overseasCompany.companyAddress"
  | "overseasCompany.companyTel";

export const usePersonalOfficeInputFeature = () => {
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useFormContext<PersonalOfficeFormValues>();
  const { patch } = usePatchUserInfo();

  const companyTypes = useCompanyTypeMaster();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string>();
  const { companies, searchCompanyNames } = useAutoCompleteCompanyName(
    companyTypes.companyTypes ?? [],
  );

  useEffect(() => {
    if (companyTypes.status === "error") {
      navigate(path.error.root, { replace: true });
    }
  }, [companyTypes.status]);

  const companyName = watch("domesticCompany.companyName");
  useEffect(() => {
    const company = companies.find((v) => v.companyName === companyName);
    if (!company) return;
    setValue("domesticCompany.companyNumber", company?.companyNumber ?? "");
    setValue("domesticCompany.companyTypeCodeFront", company.typeCodeFront);
    setValue("domesticCompany.companyTypeCodeBack", company.typeCodeBack);
  }, [companyName, companies]);

  if (companyTypes.status !== "ok") {
    return { status: "loading" } as const;
  }

  const errorMessage = t("personal.edit.office.error", {
    returnObjects: true,
  });

  const getCompanyCountryFlag = (countryCode: string) =>
    countryCode === "0" ? "domestic" : "overseas";
  const companyCountryFlag = getCompanyCountryFlag(watch("companyCountryFlag"));

  const handleChangeUserInfo: SubmitHandler<PersonalOfficeFormValues> = async (
    input: PersonalOfficeFormValues,
  ): Promise<void> => {
    try {
      await patch(buildUserInfoPatch(input), true);
      setValue("inputDone", true);
      navigate(path.personal.office.confirm);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "invalid_attribute") {
        const error = (e as PatchUserInfoError).attributes;
        const errorFields = [];
        const eo = error.overseasCompany;
        const ed = error.domesticCompany;
        const fullWidthErr = {
          message: errorMessage.single_character_full_width,
          type: "invalid_character",
        };
        const halfWidthErr = {
          message: errorMessage.single_character_half_width,
        };
        const otherErr = {
          message: errorMessage.single_other,
        };
        const isValidError = (value: string | undefined) => {
          return value === "single" || value === "required";
        };

        if (companyCountryFlag === "domestic") {
          if (isValidError(ed?.name)) {
            setError("domesticCompany.companyName", fullWidthErr);
            errorFields.push("domesticCompany.companyName");
          }
          if (isValidError(ed?.businessUnit)) {
            setError("domesticCompany.companyBusinessUnit", fullWidthErr);
            errorFields.push("domesticCompany.companyBusinessUnit");
          }
          if (isValidError(ed?.zipCode)) {
            setError("domesticCompany.companyZipCode", otherErr);
            errorFields.push("domesticCompany.companyZipCode");
          }
          if (isValidError(ed?.address)) {
            setError("domesticCompany.companyAddress", fullWidthErr);
            errorFields.push("domesticCompany.companyAddress");
          }
          if (isValidError(ed?.tel)) {
            setError("domesticCompany.companyTel", otherErr);
            errorFields.push("domesticCompany.companyTel");
          }
        } else {
          if (isValidError(eo?.name)) {
            setError("overseasCompany.companyName", halfWidthErr);
            errorFields.push("overseasCompany.companyName");
          }
          if (isValidError(eo?.address)) {
            setError("overseasCompany.companyAddress", halfWidthErr);
            errorFields.push("overseasCompany.companyAddress");
          }
          if (isValidError(eo?.tel)) {
            setError("overseasCompany.companyTel", halfWidthErr);
            errorFields.push("overseasCompany.companyTel");
          }
        }
        const errorFirstField = errorFields.at(0);
        if (errorFirstField) {
          setFocus(errorFirstField as FieldName);
        }
      } else if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const domesticZipCodeValidator = (value: string) => {
    if (companyCountryFlag !== "domestic") return undefined;
    return value !== "" && !value.match(/^[0-9]{7}$/)
      ? errorMessage.invalid_company_zip_format
      : undefined;
  };

  const domesticTelValidator = (value: string) => {
    if (companyCountryFlag !== "domestic") return undefined;
    return value !== "" && !value.match(/^[0-9]+$/)
      ? errorMessage.invalid_company_tel_character
      : undefined;
  };

  const domesticTypeLocationValidator = () => {
    if (companyCountryFlag !== "domestic") return undefined;
    const front = getValues("domesticCompany.companyTypeCodeFront");
    const back = getValues("domesticCompany.companyTypeCodeBack");
    if (front !== "0" && back !== "0") {
      const e = errorMessage.invalid_company_type_location;
      setError("domesticCompany.companyTypeCodeBack", { message: e });
      setError("domesticCompany.companyTypeCodeFront", { message: e });
    } else {
      clearErrors("domesticCompany.companyTypeCodeBack");
      clearErrors("domesticCompany.companyTypeCodeFront");
    }
  };

  const registers = {
    companyCountryFlag: register("companyCountryFlag", {
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        const next = e.target.value;
        if (getCompanyCountryFlag(next) !== companyCountryFlag) {
          clearErrors();
        }
      },
    }),
    domesticCompany: {
      companyTypeCodeFront: register("domesticCompany.companyTypeCodeFront", {
        onChange: domesticTypeLocationValidator,
      }),
      companyTypeCodeBack: register("domesticCompany.companyTypeCodeBack", {
        onChange: domesticTypeLocationValidator,
      }),
      companyName: register("domesticCompany.companyName", {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setValue("domesticCompany.companyNumber", "");
          searchCompanyNames(e.target.value);
        },
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("domesticCompany.companyName", value);
        },
      }),
      companyBusinessUnit: register("domesticCompany.companyBusinessUnit", {
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("domesticCompany.companyBusinessUnit", value);
        },
      }),
      companyZipCode: register("domesticCompany.companyZipCode", {
        validate: domesticZipCodeValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toHalfWidth(e.target.value);
          const hasError = errors.domesticCompany?.companyZipCode !== undefined;
          setValue("domesticCompany.companyZipCode", value, {
            shouldValidate: hasError,
          });
        },
      }),
      companyAddress: register("domesticCompany.companyAddress", {
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("domesticCompany.companyAddress", value);
        },
      }),
      companyTel: register("domesticCompany.companyTel", {
        validate: domesticTelValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toHalfWidth(e.target.value);
          const hasError = errors.domesticCompany?.companyTel !== undefined;
          setValue("domesticCompany.companyTel", value, {
            shouldValidate: hasError,
          });
        },
      }),
    },
    overseasCompany: {
      companyName: register("overseasCompany.companyName"),
      companyAddress: register("overseasCompany.companyAddress"),
      companyTel: register("overseasCompany.companyTel"),
    },
  };

  const retErrors = {
    ...errors,
    ...(apiError ? { api: { message: apiError } } : undefined),
  };

  const onCompanyClick = () => {
    searchCompanyNames(getValues("domesticCompany.companyName"));
  };

  const onCompanyNameAutoCompleteSelect = (companyName: string) => {
    setValue("domesticCompany.companyName", companyName);
    searchCompanyNames(companyName);
  };

  return {
    status: "ok",
    registers,
    handleSubmit: handleSubmit(handleChangeUserInfo),
    errors: retErrors,
    companyCountryFlag,
    companyTypes,
    companyAutoCompleteList: companies.map((v) => v.companyName),
    onCompanyClick,
    onCompanyNameAutoCompleteSelect,
    buttonEnabled: Object.keys(retErrors).length === 0,
  } as const;
};
