import type * as React from "react";

import {
  Box,
  FormControl,
  InputField,
  Textarea,
  Typography2,
  type UseFormRegisterReturn,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { Row } from "@/components/ui/Row";
import RequiredTag from "@/components/ui/Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.OverseasAddressField";

type OverseasAddressFieldProps = {
  errors?: {
    address1?: { message?: string };
    address2?: { message?: string };
    address3?: { message?: string };
    zipCode?: { message?: string };
    tel?: { message?: string };
  };
  registers?: {
    address1?: UseFormRegisterReturn<string>;
    address2?: UseFormRegisterReturn<string>;
    address3?: UseFormRegisterReturn<string>;
    zipCode?: UseFormRegisterReturn<string>;
    tel?: UseFormRegisterReturn<string>;
  };
};

const OverseasAddressField: React.FC<OverseasAddressFieldProps> = ({
  errors,
  ...props
}) => {
  const { t } = useTranslation();
  const placeholder = t("personal.edit.address.placeholder.overseas", {
    returnObjects: true,
  });
  const label = t("personal.edit.address.label.overseas", {
    returnObjects: true,
  });

  const addressError = (
    errors?.address1 ||
    errors?.address2 ||
    errors?.address3
  )?.message;

  return (
    <>
      <Box>
        <Typography2
          as="legend"
          size="16"
          bold
          className="mb-2"
          color={addressError ? "red90" : undefined}
        >
          {label.address_head}
          <RequiredTag on={false} />
        </Typography2>

        <fieldset className="nid-account-edit-fieldset">
          <Row className="nid-space-between mb-1">
            <label htmlFor="input-address1">{label.address1}</label>
          </Row>
          <Row className="nid-input-control-head">
            <Textarea
              labelPosition="horizontal"
              className="w-full"
              placeholder={placeholder.address1}
              aria-label={placeholder.address1}
              autoComplete="address-line1"
              id="input-address1"
              data-testid="input-address1"
              status={errors?.address1 ? "error" : undefined}
              {...props.registers?.address1}
            />
          </Row>
        </fieldset>

        <fieldset className="nid-account-edit-fieldset">
          <Row className="nid-space-between mb-1">
            <label htmlFor="input-address2">{label.address2}</label>
          </Row>
          <Row className="nid-input-control-head mb-1">
            <Textarea
              labelPosition="horizontal"
              className="w-full"
              placeholder={placeholder.address2}
              aria-label={placeholder.address2}
              autoComplete="address-line2"
              id="input-address2"
              data-testid="input-address2"
              status={errors?.address2 ? "error" : undefined}
              {...props.registers?.address2}
            />
          </Row>
        </fieldset>

        <fieldset className="nid-account-edit-fieldset">
          <Row className="nid-space-between mb-1">
            <label htmlFor="input-address3">{label.address3}</label>
          </Row>
          <Row className="nid-input-control-head mb-1">
            <Textarea
              labelPosition="horizontal"
              className="w-full"
              placeholder={placeholder.address3}
              aria-label={placeholder.address3}
              autoComplete="address-line3"
              id="input-address3"
              data-testid="input-address3"
              status={errors?.address3 ? "error" : undefined}
              {...props.registers?.address3}
            />
          </Row>
          <FormControl.Validation
            className="nid-error-field"
            status={addressError ? "error" : undefined}
            data-testid="error-address"
          >
            {addressError}
          </FormControl.Validation>
        </fieldset>
      </Box>

      <fieldset className="nid-account-edit-fieldset">
        <Typography2
          as="legend"
          size="16"
          bold
          className="mb-1"
          color={errors?.zipCode ? "red90" : undefined}
        >
          <Box className="nid-item-center" />
          <label htmlFor="input-zip-code">{label.zip_code}</label>
          <RequiredTag on={false} />
        </Typography2>
        <Row className="mb-1">
          <Box className="nid-form-postcode">
            <InputField
              placeholder={placeholder.zip_code}
              aria-label={placeholder.zip_code}
              id="input-zip-code"
              data-testid="input-zip-code"
              autoComplete="postal-code"
              status={errors?.zipCode ? "error" : undefined}
              {...props.registers?.zipCode}
            />
          </Box>
        </Row>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.zipCode ? "error" : undefined}
          data-testid="error-zip-code"
        >
          {errors?.zipCode?.message}
        </FormControl.Validation>
      </fieldset>

      <fieldset className="nid-account-edit-fieldset">
        <Typography2
          as="legend"
          size="16"
          bold
          color={errors?.tel ? "red90" : undefined}
        >
          <label htmlFor="input-tel">{label.tel}</label>
          <RequiredTag on={false} />
        </Typography2>
        <Row className="nid-input-control-head">
          <InputField
            max-length={60}
            id="input-tel"
            data-testid="input-tel"
            placeholder={placeholder.tel}
            aria-label={placeholder.tel}
            status={errors?.tel ? "error" : undefined}
            autoComplete="tel"
            {...props.registers?.tel}
          />
        </Row>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.tel ? "error" : undefined}
          data-testid="error-tel"
        >
          {errors?.tel?.message}
        </FormControl.Validation>
      </fieldset>
    </>
  );
};

OverseasAddressField.displayName = DISPLAY_NAME;
const Root = OverseasAddressField;
export { Root, OverseasAddressField };
export type { OverseasAddressFieldProps };
export default OverseasAddressField;
