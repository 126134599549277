import { useEffect, useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import type { PersonalOfficeFormValues } from "@/features/Personal/Office/PersonalOfficeFormProvider";
import {
  type PatchUserInfoError,
  type UserInfoPatch,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useCompanyTypeMaster } from "nid-common";

import { useUserInfoLabel } from "@/hooks/useUserInfoLabel";
import { path } from "@/routes";

export const usePersonalOfficeConfirmFeature = () => {
  const { handleSubmit, getValues } =
    useFormContext<PersonalOfficeFormValues>();

  const companyTypesReturn = useCompanyTypeMaster();
  const navigate = useNavigate();
  const { getZipCodeLabel } = useUserInfoLabel();
  const { patch, userInfo: confirmUserInfo } = usePatchUserInfo();
  const { t } = useTranslation();
  const [apiError, setApiError] = useState<string>();
  useEffect(() => {
    if (companyTypesReturn.status === "error") {
      navigate(path.error.root, { replace: true });
    }
  }, [companyTypesReturn.status, navigate]);

  const inputDone = getValues("inputDone");
  useEffect(() => {
    const statusOk = companyTypesReturn.status === "ok";
    if (!inputDone && statusOk) {
      navigate(path.personal.office.root, { replace: true });
    }
  }, [inputDone, navigate, companyTypesReturn.status]);

  if (companyTypesReturn.status !== "ok") {
    return { status: "loading" } as const;
  }

  useEffect(() => {
    if (!confirmUserInfo && companyTypesReturn.status === "ok") {
      navigate(path.personal.office.root, { replace: true });
    }
  }, [confirmUserInfo, companyTypesReturn.status, navigate]);

  if (!confirmUserInfo) {
    return { status: "loading" } as const;
  }

  const handleConfirmSubmit: SubmitHandler<PersonalOfficeFormValues> = async (
    _input: PersonalOfficeFormValues,
  ): Promise<void> => {
    try {
      await patch(confirmUserInfo, false);
      navigate(path.personal.office.complete);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "optimistic_locked") {
        setApiError(t("personal.edit.errors.optimistic_locked"));
      } else {
        navigate(path.error.root);
      }
    }
  };

  const getDisplayValues = () => {
    const valueOrUndefined = (
      value: string | undefined | null,
    ): string | undefined => {
      return value ? value : undefined;
    };

    const getDisplayCompanyName = (
      patchUserInfo: UserInfoPatch,
    ): string | undefined => {
      const company = patchUserInfo.domesticCompany;
      if (!company) {
        return undefined;
      }

      const companyType = companyTypesReturn.companyTypes.find(
        (item) => item.code === company.typeCode,
      );

      const companyName = company.name ? company.name : "";

      if (!companyType) {
        return companyName;
      }

      if (company.typeLocation === "1") {
        return `${companyType.name}${companyName}`;
      }
      if (company.typeLocation === "2") {
        return `${companyName}${companyType.name}`;
      }

      return companyName;
    };

    return {
      domestic: confirmUserInfo.domesticCompany
        ? {
            companyLocation: t("attribute_values.office.domestic"),
            companyName: getDisplayCompanyName(confirmUserInfo),
            companyBusinessUnit: valueOrUndefined(
              confirmUserInfo.domesticCompany.businessUnit,
            ),
            companyZipCode: confirmUserInfo.domesticCompany.zipCode
              ? getZipCodeLabel(confirmUserInfo.domesticCompany.zipCode)
              : undefined,
            companyAddress: valueOrUndefined(
              confirmUserInfo.domesticCompany.address,
            ),
            companyTel: valueOrUndefined(confirmUserInfo.domesticCompany.tel),
          }
        : undefined,
      overseas: confirmUserInfo.overseasCompany
        ? {
            companyLocation: t("attribute_values.office.overseas"),
            companyName: valueOrUndefined(confirmUserInfo.overseasCompany.name),
            companyAddress: valueOrUndefined(
              confirmUserInfo.overseasCompany.address,
            ),
            companyTel: valueOrUndefined(confirmUserInfo.overseasCompany.tel),
          }
        : undefined,
    };
  };

  return {
    displayValues: getDisplayValues(),
    handleSubmit: handleSubmit(handleConfirmSubmit),
    apiError,
    enableButton: apiError === undefined,
    inputDone,
  };
};
