import { useState } from "react";

import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  type InputUserInfoValues,
  buildUserInfoPatch,
} from "@/features/Personal/Info/PersonalInfoFormProvider";
import {
  type PatchUserInfoError,
  usePatchUserInfo,
} from "@/hooks/usePatchUserInfo";
import { useUserInfo } from "@/hooks/useUserInfo";
import { path } from "@/routes";
import { halfToFullWidthKana, toFullWidth } from "nid-common";

type FieldName =
  | "name.first"
  | "name.last"
  | "nameKana.first"
  | "nameKana.last";

export const usePersonalInfoInputFeature = () => {
  const { t } = useTranslation();

  const errorMessage = t("personal.edit.info.errors", { returnObjects: true });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    formState: { errors },
    watch,
  } = useFormContext<InputUserInfoValues>();
  const navigate = useNavigate();
  const { patch } = usePatchUserInfo();
  const [apiError, setApiError] = useState<string>();
  const { userInfo, status, error: userInfoError } = useUserInfo();

  if (status === "loading") {
    return { status: "loading" } as const;
  }
  if (status === "error") {
    return { status: "error", error: userInfoError } as const;
  }

  const required = {
    birth: userInfo.birth !== undefined,
  };

  const handleChangeUserInfo: SubmitHandler<InputUserInfoValues> = async (
    data: InputUserInfoValues,
  ) => {
    if (
      !required.birth &&
      data.birth?.year === "0" &&
      data.birth?.month === "0" &&
      data.birth?.date === "0"
    ) {
      data.birth = undefined;
    }

    try {
      await patch(buildUserInfoPatch(data), true);
      setValue("inputDone", true);
      navigate(path.personal.info.confirm);
    } catch (e) {
      if ((e as PatchUserInfoError).error === "invalid_attribute") {
        const error = (e as PatchUserInfoError).attributes;
        const errorFields = [];
        const nameError = {
          message: errorMessage.name.single,
          type: "invalid_character",
        };
        const nameKanaError = {
          message: errorMessage.name_kana.single,
          type: "invalid_character",
        };
        const isValidError = (value: string | undefined) => {
          return value === "single" || value === "required";
        };

        if (isValidError(error.lastName)) {
          setError("name.last", nameError);
          errorFields.push("name.last");
        }
        if (isValidError(error.firstName)) {
          setError("name.first", nameError);
          errorFields.push("name.first");
        }
        if (isValidError(error.lastNameKana)) {
          setError("nameKana.last", nameKanaError);
          errorFields.push("nameKana.last");
        }
        if (isValidError(error.firstNameKana)) {
          setError("nameKana.first", nameKanaError);
          errorFields.push("nameKana.first");
        }

        const errorFirstField = errorFields.at(0);
        if (errorFirstField) {
          if (window) window.scrollTo(0, 50);
          setFocus(errorFirstField as FieldName);
        }
      } else {
        if ((e as PatchUserInfoError).error === "optimistic_locked") {
          setApiError(t("personal.edit.errors.optimistic_locked"));
        } else {
          navigate(path.error.root);
        }
      }
    }
  };

  const nameValidator = (value: string) => {
    return !value ? errorMessage.name.required : undefined;
  };

  const nameKanaValidator = (value: string) => {
    return !value ? errorMessage.name_kana.required : undefined;
  };

  const birthValidator = (value: string) => {
    if (
      watch("birth.year") !== "0" &&
      watch("birth.month") !== "0" &&
      watch("birth.date") !== "0"
    ) {
      return undefined;
    }
    if (
      watch("birth.year") === "0" &&
      watch("birth.month") === "0" &&
      watch("birth.date") === "0"
    ) {
      return required.birth ? errorMessage.birth.required : undefined;
    }
    return value === "0" ? errorMessage.birth.required : undefined;
  };

  const registers = {
    name: {
      first: register("name.first", {
        validate: nameValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("name.first", value);
        },
      }),
      last: register("name.last", {
        validate: nameValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = toFullWidth(e.target.value);
          setValue("name.last", value);
        },
      }),
    },
    nameKana: {
      first: register("nameKana.first", {
        validate: nameKanaValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = halfToFullWidthKana(e.target.value);
          setValue("nameKana.first", value);
        },
      }),
      last: register("nameKana.last", {
        validate: nameKanaValidator,
        onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = halfToFullWidthKana(e.target.value);
          setValue("nameKana.last", value);
        },
      }),
    },
    birth: {
      year: register("birth.year", { validate: birthValidator }),
      month: register("birth.month", { validate: birthValidator }),
      date: register("birth.date", { validate: birthValidator }),
    },
    sex: register("sex"),
  };

  const retErrors = {
    ...errors,
    ...(apiError ? { api: { message: apiError } } : undefined),
  };

  const buttonEnabled = Object.keys(errors).length === 0 && !apiError;

  return {
    status: "ok",
    required,
    errors: retErrors,
    handleSubmit: handleSubmit(handleChangeUserInfo),
    buttonEnabled,
    registers,
  };
};
