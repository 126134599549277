import { Box, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import styles from "../CardPaymentHistories.module.css";

type PaymentOrdererProps = {
  billingUser: string;
  billingDate: string;
};

export const PaymentOrderer = ({
  billingUser,
  billingDate,
}: PaymentOrdererProps) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-0">
      <Box className={styles.sectionInnerGap}>
        <Box className="nid-column-item-start">
          <Typography2 as={"h3"} bold>
            {billingUser} {t("payments.histories.detail.billing_user_label")}
          </Typography2>
        </Box>
        <hr className="nid-separator" />
        <Box>
          <Box className="print-hidden nid-hidden-tablet">
            <Box className={`${styles.header}`}>
              <Typography2 as="p" size="14">
                {t("payments.histories.detail.billing_date_label")}
              </Typography2>
              <Typography2 as="p" size="14" data-testid={"billing-date-mobile"}>
                {billingDate}
              </Typography2>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
