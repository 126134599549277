import type * as React from "react";

import { Box, Typography2 } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityMfaBackupCodeFeature } from "@/features/Security/Mfa/BackupCode/SecurityMfaBackupCodeFeature";

const SecurityMfaBackupCodePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box className="print-page">
      <Box className="print-section">
        <ArticleTitle
          title={t("security.mfa.backup_code.title")}
          testId="article-security-mfa-backup-code"
        >
          <Typography2 as="p" className="nid-article-description">
            {t("security.mfa.backup_code.description")}
          </Typography2>
        </ArticleTitle>
      </Box>
      <Box className="nid-section-inner-gap24">
        <SecurityMfaBackupCodeFeature />
      </Box>
    </Box>
  );
};

export default SecurityMfaBackupCodePage;
