import type React from "react";

import { Box, LinkButton, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import styles from "./PersonalItem.module.css";

interface Props {
  title: string;
  changePageLink: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  values: {
    key: string | undefined;
    values: string | undefined | string[];
  }[];
  testId: string;
}

export const PersonalItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const allEmpty =
    props.values.length === 0 ||
    props.values.every(
      (item) =>
        typeof item.values !== "string" &&
        (item.values === undefined || item.values.length === 0),
    );

  const items = props.values.map((item) => {
    return {
      key: item.key,
      values:
        typeof item.values === "string"
          ? [item.values]
          : item.values === undefined || item.values.length === 0
            ? [undefined]
            : item.values,
    };
  });

  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow"
      data-testid={props.testId}
    >
      <Box className="nid-section-inner">
        <Box className="nid-section-header">
          <Typography2 as="h2">
            <Box className="nid-section-title">{props.title}</Box>
          </Typography2>
          <LinkButton
            to={props.changePageLink}
            data-testid={`${props.testId}-button`}
            variant="border"
            size="sm"
            onClick={props.onClick}
            className={styles.changeButton}
          >
            {t("personal.change")}
          </LinkButton>
        </Box>
        <Box className="nid-section-body">
          {allEmpty ? (
            <Box className="nid-account-item-count0">
              <Typography2 as="p" size="16" className="nid-account-item-text">
                {t("personal.empty")}
              </Typography2>
            </Box>
          ) : (
            items.map(({ key, values }, index) => {
              return (
                <Box
                  className={
                    index === props.values.length - 1
                      ? "nid-account-item--noborder"
                      : "nid-account-item"
                  }
                  // biome-ignore lint/suspicious/noArrayIndexKey: TODO 適切なキーを付ける
                  key={index}
                  data-testid={`${props.testId}-item-${index}`}
                >
                  {key && (
                    <Typography2
                      as="label"
                      bold
                      size="14"
                      className="nid-account-item-label"
                    >
                      {key}
                    </Typography2>
                  )}
                  {values.map((value, valueIndex) => {
                    return (
                      <Typography2
                        as="p"
                        size="16"
                        className="nid-account-item-text"
                        // biome-ignore lint/suspicious/noArrayIndexKey: TODO 適切なキーを付ける
                        key={`${index}-${valueIndex}`}
                      >
                        {value}
                      </Typography2>
                    );
                  })}
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};
