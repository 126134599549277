import type React from "react";

import { useTranslation } from "react-i18next";

import { PasskeyHero } from "@/components/ui/Passkeys/PasskeyHero";
import { path, enableSecurityPasskey } from "@/routes";
import { ContentSkeletonLoader, useMfaStatus, usePasskeys } from "nid-common";
import { Box, LinkButton, Typography2 } from "nikkei-ui";
import styles from "./SecurityFeature.module.css";

interface SectionProps {
  title: string;
  buttonTo: string;
  children: React.ReactNode;
  testId: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  buttonTo,
  children,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      as="section"
      className="nid-section nid-section-overflow"
      data-testid={testId}
    >
      <Box className="nid-section-inner-gap0">
        <Box className={`${styles.sectionHeader} mb-4`}>
          <Typography2 as="h2">
            <Box className="nid-section-title">{title}</Box>
          </Typography2>
          <LinkButton
            to={buttonTo}
            data-testid={`${testId}-button`}
            variant="border"
            size="sm"
            className={styles.changeButton}
          >
            {t("security.top.button.change")}
          </LinkButton>
        </Box>
        <Box className="pt-3 pb-3">
          <Typography2 as="p" size="16" data-testid={`${testId}-value`}>
            {children}
          </Typography2>
        </Box>
      </Box>
    </Box>
  );
};

const PasskeySection: React.FC = () => {
  const { t } = useTranslation();
  const { status, data } = usePasskeys();

  if (!enableSecurityPasskey) {
    return null;
  }

  if (status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  const passkeyCount = data.passkeys.length;

  if (passkeyCount === 0) {
    return (
      <Box as="section" className="nid-section-inner mb-6">
        <PasskeyHero
          link={path.security.passkeys.root}
          testId="security-item-passkey-empty"
        />
      </Box>
    );
  }

  return (
    <Section
      title={t("security.top.passkey.title")}
      buttonTo={path.security.passkeys.root}
      testId="security-item-passkey"
    >
      {t("security.top.passkey.subtext", { count: passkeyCount })}
    </Section>
  );
};

export const SecurityFeature: React.FC = () => {
  const { t } = useTranslation();

  const { status, data } = useMfaStatus();

  if (status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  return (
    <>
      {enableSecurityPasskey && <PasskeySection />}
      <Section
        title={t("security.top.password.title")}
        buttonTo={path.security.passwordChange.root}
        testId="security-item-password-change"
      >
        ***********
      </Section>
      <Section
        title={t("security.top.mfa.title")}
        buttonTo={path.security.mfa.root}
        testId="security-item-mfa"
      >
        {t(`security.top.mfa.subtext.${data.mfa_status}`)}
      </Section>
    </>
  );
};
