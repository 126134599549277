import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { path } from "@/routes";
import { postMfaDisable } from "nid-common/api/account";
import { useMfaStatus } from "nid-common/hooks/useMfaStatus";

export const useSecurityMfaDisableFeature = () => {
  const navigate = useNavigate();
  const useMfaStatusReturn = useMfaStatus();

  useEffect(() => {
    if (useMfaStatusReturn.data?.mfa_status === "none") {
      navigate(path.security.mfa.root, { replace: true });
    }
  }, [useMfaStatusReturn.data]);

  const [isDisabling, setIsDisabling] = useState(false);
  const handleDisableMfa = async () => {
    try {
      setIsDisabling(true);
      await postMfaDisable();
      navigate(path.security.mfa.disable.complete);
    } catch (_) {
      navigate(path.error.root);
    } finally {
      setIsDisabling(false);
    }
  };

  return {
    loading: useMfaStatusReturn.status === "loading",
    handleDisableMfa,
    isDisabling,
  };
};
