import { Box, CloseIcon, Link2, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "nid-common";
import styles from "./Passkey.module.css";

import PasskeyBiometricAuth from "@/components/icon/PasskeyBiometricAuth.png";

type PasskeyHeroProps = {
  link: string;
  enableCloseButton?: boolean;
  testId?: string;
};

export const PasskeyHero: React.FC<PasskeyHeroProps> = ({
  link,
  enableCloseButton = false,
  testId,
}) => {
  const { t } = useTranslation();
  const { value: show, setValue: setShow } = useLocalStorage(
    "passkey-hero-show",
    true,
  );
  return (
    (!enableCloseButton || show) && (
      <Box className={styles.hero} data-testid={testId}>
        <Box className={styles.heroHead}>
          <Typography2
            bold
            size="16"
            color="gray120"
            className={styles.heroTitle}
          >
            {t("security.top.passkey.hero.title")}
          </Typography2>
          {enableCloseButton && (
            <Box
              as="div"
              // biome-ignore lint/a11y/useSemanticElements: TODO: button 要素に置き換える
              role="button"
              className={styles.close}
              onClick={() => setShow(false)}
            >
              <CloseIcon color={"gray90"} />
            </Box>
          )}
        </Box>
        <Box className={styles.heroBody}>
          <Box className={styles.heroBodyLeft}>
            <Typography2 size="13" color="gray120" className={styles.heroText}>
              {t("security.top.passkey.hero.description")}
            </Typography2>
            <Link2 to={link} size="14">
              {t("security.top.passkey.hero.link")}
            </Link2>
          </Box>
          <Box className={styles.heroBodyRight}>
            <img
              src={PasskeyBiometricAuth}
              alt={t("security.top.passkey.hero.title")}
            />
          </Box>
        </Box>
      </Box>
    )
  );
};
