import { Box, Button, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { AlertMessage } from "@/components/ui/Message/AlertMessage";
import {
  PasskeyItem,
  type PasskeyItemProps,
} from "@/components/ui/Passkeys/PasskeyItem";

type RevokingStatusType = "success" | "error" | "confirm" | "loading";

type PasskeyRevokeModalProps = PasskeyItemProps & {
  handleRevokePassKey: () => void;
  testId?: string;
  revokingStatus: RevokingStatusType;
  clearRevokingStatus: () => void;
  closeModal: () => void;
};

export const PasskeyRevokeModalContent: React.FC<PasskeyRevokeModalProps> = ({
  title,
  createdAtDateTime,
  lastUsedAtDateTime,
  backupEligible,
  handleRevokePassKey,
  testId,
  revokingStatus,
  closeModal,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="nid-column-item-start nid-item-gap24">
      {revokingStatus === "success" ? (
        <>
          <Typography2 lineHeight="180">
            {t("security.passkey.delete.complete_description")}
          </Typography2>
          <Button
            size={"full"}
            data-testid={"close-passkey-complete-modal"}
            type={"button"}
            onClick={closeModal}
          >
            {t("security.passkey.button.close")}
          </Button>
        </>
      ) : (
        <>
          <PasskeyItem
            title={title}
            createdAtDateTime={createdAtDateTime}
            lastUsedAtDateTime={lastUsedAtDateTime}
            backupEligible={backupEligible}
            testId={`${testId}-revoke-confirm`}
          />
          <AlertMessage
            hasIcon
            title={t("security.passkey.delete.caution_title")}
          >
            {t("security.passkey.delete.caution_body")}
          </AlertMessage>
          <Button
            size={"full"}
            data-testid={`${testId}-delete-confirm-modal-button`}
            type={"button"}
            disabled={revokingStatus === "loading"}
            onClick={() => handleRevokePassKey()}
          >
            {t("security.passkey.button.delete_confirm")}
          </Button>
          <BackButton
            to="#"
            onClick={closeModal}
            buttonText={t("security.passkey.button.close")}
          />
        </>
      )}
    </Box>
  );
};
