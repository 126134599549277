import type React from "react";

import {
  Box,
  FormControl,
  InputField,
  Textarea,
  Typography2,
  type UseFormRegisterReturn,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import RequiredTag from "@/components/ui/Tag/RequiredTag";
import type { PersonalOfficeFormValues } from "@/features/Personal/Office/PersonalOfficeFormProvider";

type Props = {
  testId?: string;
  registers?: {
    [K in keyof PersonalOfficeFormValues["overseasCompany"]]?: UseFormRegisterReturn<string>;
  };
  errors?: {
    [K in keyof PersonalOfficeFormValues["overseasCompany"]]?: {
      message?: string;
    };
  };
};

const OverseasCompanyField: React.FC<Props> = ({ registers, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <fieldset>
        <Box className="mb-5">
          <Typography2
            as="legend"
            lineHeight="135"
            bold
            className="mb-2"
            color={errors?.companyName ? "red90" : undefined}
          >
            <label htmlFor="input-company-name">
              {t("attributes.companyName")}
            </label>
            <RequiredTag on={false} />
          </Typography2>
          <Typography2 size="12">
            {t("personal.edit.office.text.company_name_helper")}
          </Typography2>
          <Box className="nid-input-control-head">
            <InputField
              id="input-company-name"
              data-testid="input-company-name"
              placeholder={t(
                "personal.edit.office.placeholder.overseas_company_name",
              )}
              maxLength={100}
              status={errors?.companyName ? "error" : undefined}
              {...registers?.companyName}
            />
          </Box>
          <FormControl.Validation
            className="nid-error-field"
            status={errors?.companyName ? "error" : undefined}
            data-testid="error-company-name"
          >
            {errors?.companyName?.message}
          </FormControl.Validation>
        </Box>
      </fieldset>

      <fieldset>
        <Box className="mb-5">
          <Typography2
            as="legend"
            bold
            className="mb-2"
            color={errors?.companyAddress ? "red90" : undefined}
          >
            <label htmlFor="input-company-address">
              {t("attributes.companyAddress")}
            </label>
            <RequiredTag on={false} />
          </Typography2>
          <Textarea
            id="input-company-address"
            data-testid="input-company-address"
            placeholder={t(
              "personal.edit.office.placeholder.overseas_company_address",
            )}
            className="nid-input-control-head mb-1"
            maxLength={300}
            status={errors?.companyAddress ? "error" : undefined}
            {...registers?.companyAddress}
          />
          <FormControl.Validation
            className="nid-error-field"
            status={errors?.companyAddress ? "error" : undefined}
            data-testid="error-company-address"
          >
            {errors?.companyAddress?.message}
          </FormControl.Validation>
        </Box>
      </fieldset>

      <fieldset>
        <Typography2
          as="legend"
          bold
          className="mb-1"
          color={errors?.companyTel ? "red90" : undefined}
        >
          <label htmlFor="input-company-tel">
            {t("attributes.companyTel")}
          </label>
          <RequiredTag on={false} />
        </Typography2>
        <Box className="nid-input-control-head">
          <InputField
            id="input-company-tel"
            data-testid="input-company-tel"
            maxLength={25}
            placeholder={t(
              "personal.edit.office.placeholder.overseas_company_tel",
            )}
            status={errors?.companyTel ? "error" : undefined}
            {...registers?.companyTel}
          />
        </Box>
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.companyTel ? "error" : undefined}
          data-testid="error-company-tel"
        >
          {errors?.companyTel?.message}
        </FormControl.Validation>
      </fieldset>
    </>
  );
};

OverseasCompanyField.displayName = "Nid.Form.OverseasCompanyField";
const Root = OverseasCompanyField;
export { Root, OverseasCompanyField };
export default OverseasCompanyField;
