import type { CreditCardBrand } from "@/hooks/usePaymentCards";
import { path } from "@/routes";
import { encodeStringToBase64Url } from "nid-common";
import { getPaymentHistories } from "nid-common/api/account";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

export type PaymentHistory = {
  encodedPaymentNo: string;
  paymentType: "service_charge" | "discount_penalty" | "refund";
  paymentTypeName: string;
  // 支払日
  salesDate: Date;
  refundTargetSalesDate?: Date;
  amount: number;
  serviceId: string;
  serviceName: string;
  planId?: string;
  planName?: string;
  paymentHistoryDisplayString?: string;
  paymentHistoryUrl:
    | {
        external: false;
        to: string;
      }
    | {
        external: true;
        href: string;
      }
    | undefined;
  card: {
    id: string;
    paymentCardType:
      | "credit_card"
      | "apple_pay"
      | "au_easy_payment"
      | "d_payment";
    isDeleted: boolean;
    last4?: string;
    expire?: {
      month: string;
      year: string;
      formatString: string;
    };
    brand?: CreditCardBrand;
  };
};

export const usePaymentHistories = (): {
  status: "loading" | "ok";
  histories: PaymentHistory[];
} => {
  const histories = useSWR("/account/payments/histories", getPaymentHistories, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (histories.error) {
      const response = histories.error.response?.data;
      if (response.error === "nkdk_inactive") {
        navigate(path.payments.maintenance);
      } else {
        navigate(path.error.root);
      }
    }
  }, [histories.error]);

  if (histories.isLoading || !histories.data) {
    return {
      histories: <PaymentHistory[]>[],
      status: "loading",
    } as const;
  }

  return {
    status: "ok",
    histories:
      histories.data.data.payments.map((payment) => {
        const encodedPaymentNo = encodeStringToBase64Url(
          payment.encrypted_payment_no,
        );
        return {
          encodedPaymentNo: encodedPaymentNo,
          paymentType:
            payment.payment_type === "1"
              ? "service_charge"
              : payment.payment_type === "2"
                ? "discount_penalty"
                : "refund",
          paymentTypeName: payment.payment_type_name,
          salesDate: toDate(payment.sales_date),
          refundTargetSalesDate:
            payment.payment_type === "9" &&
            payment.refund_target_sales_date !== undefined
              ? toDate(payment.refund_target_sales_date)
              : undefined,
          amount: payment.amount,
          serviceId: payment.service_id,
          serviceName: payment.service_name,
          planId: payment.plan_id,
          planName: payment.plan_name,
          paymentHistoryDisplayString: payment.payment_history_display_string,
          paymentHistoryUrl:
            payment.payment_type === "9"
              ? undefined
              : payment.service_charges_type === "1"
                ? {
                    external: false,
                    to: path.payments.histories.detail(encodedPaymentNo),
                  }
                : {
                    external: true,
                    href: payment.payment_history_url ?? "",
                  },
          card: {
            id: payment.card_id,
            paymentCardType:
              payment.payment_card_type === "1"
                ? "credit_card"
                : payment.payment_card_type === "2"
                  ? "apple_pay"
                  : payment.payment_card_type === "3"
                    ? "au_easy_payment"
                    : "d_payment",
            isDeleted:
              payment.payment_card_type === "1" &&
              payment.card_no_last4 === undefined,
            last4: payment.card_no_last4,
            expire: (() => {
              const exp = payment?.card_exp;
              if (exp === undefined) {
                return undefined;
              }
              return {
                month: exp.slice(0, 2),
                year: exp.slice(2, 4),
                formatString: `${exp.slice(0, 2)}/${exp.slice(2, 4)}`,
              };
            })(),
            brand: payment?.card_brand,
          },
        };
      }) ?? [],
  } as const;
};

const toDate = (value: string): Date => {
  return new Date(
    Number(value.slice(0, 4)),
    Number(value.slice(4, 6)) - 1,
    Number(value.slice(6, 8)),
  );
};
