import { useNavigate, useParams } from "react-router-dom";

import { useContractDetail } from "@/hooks/useContractDetail";
import { useRelyingPartyDisplaySettings } from "@/hooks/useRelyingPartyDisplaySettings";
import { path } from "@/routes";
import { useEffect } from "react";
export const enableServiceContract =
  import.meta.env?.VITE_FEATURE_FLAG_SERVICE_CONTRACT === "true";

export const useServiceFeature = () => {
  const { clientId } = useParams();
  const settings = useRelyingPartyDisplaySettings(clientId || "");
  const contract = enableServiceContract
    ? useContractDetail(clientId || "")
    : ({ status: "contract_not_found" } as const);
  const navigate = useNavigate();

  if (clientId === undefined) {
    return { status: "not_found" } as const;
  }

  useEffect(() => {
    if (settings.status === "not_found") {
      navigate(path.services.root);
    }
  }, [settings]);

  if (
    settings.status === "loading" ||
    settings.status === "error" ||
    settings.status === "not_found"
  ) {
    return { status: "loading" } as const;
  }

  return {
    status: "ok",
    service: {
      logo: settings.relyingParty.logo,
      name: settings.relyingParty.name,
      description: settings.relyingParty.description,
      clientId: settings.relyingParty.clientId,
      links: settings.relyingParty.links,
    },
    contractDetail: contract.contractDetail,
  };
};
